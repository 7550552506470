import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Input, Pagination,Button } from 'antd'
import { axiosPost, enterprise } from '../../../utils/requestUtil'
import { getCookieValue, setCookieValue, removeCookieValue } from '../../../utils/cookieUtil'
import { checkUser } from '../../../utils/currentUser'

import styles from './userHistory.module.css'

import playArt from '../../../assets/images/art_play.png';
import noRecord from '../../../assets/images/pic_no_record.png';
import pleaseLogin from '../../../assets/images/user_center_pleaseLogin.png';

const { Search } = Input

export default class UserHistory extends Component {
    constructor() {
        super()
        this.state = {
            user: {},
            keyword: '',
            index: 0,
            size: 12,
            userHitories: [],
            userHitoriesTotal: 0
        }
    }

    componentDidMount() {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getHitory()
            }
        })
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    getHitory = () => {
        const { keyword, index, size } = this.state
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            keyword: keyword,
            index: index,
            size: size
        }
        axiosPost(29, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    userHitories: res.data.list,
                    userHitoriesTotal: res.data.total
                })
            } else {
                this.setState({
                    userHitories: [],
                    userHitoriesTotal: 0
                })
            }
        })
    }

    searchVideo = (e) => {
        this.setState({
            keyword: e,
            index: 0
        }, () => this.getHitory())
    }

    pageChange = (e) => {
        this.setState({
            index: e - 1
        }, () => this.getHitory())
    }

    //生成结构函数
    eachHistory = (item) => {
        return <div>
            <Link target='_blank' to={{ pathname: `/home/videoplay/${item.video.id}` }}>
                <div className={styles.videoCoverBox}>
                    <img src={item.video.pictrue} alt='' />
                    <div className={styles.playIconBox}>
                        <img src={playArt} alt='' />
                    </div>
                </div>
            </Link>
            <div className={styles.videoDetail}>
                <Link target='_blank' to={{ pathname: `/home/videoplay/${item.video.id}` }}>
                    <h2>{item.video.name}</h2>
                </Link>
                <p>讲师：{item.video.teacherName}</p>
            </div>
        </div>
    }

    render() {
        const { user, userHitories, userHitoriesTotal, size, index } = this.state
        return (
            <div>
                {user.type && user.type !== '-1' ? <div>
                    <div style={{ marginBottom: '20px' }}>
                        <Row wrap={false}>
                            <Col flex='none'>
                                <span className={styles.historyTitle}>历史记录</span>
                            </Col>
                            <Col flex='auto'>
                                <Search style={{ width: '50%' }} placeholder='输入关键字搜索' onSearch={this.searchVideo} enterButton />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        {userHitories.length > 0 ? <Row gutter={[30, 30]}>
                            {userHitories.map(item => <Col sm={12} md={8} lg={8} xl={8} xxl={6} key={item.id}>
                                {this.eachHistory(item)}
                            </Col>)}
                        </Row> : <div className={styles.noDataBox}>
                                {/* 暂无历史，赶快去学习视频吧 */}
                                <img src={noRecord} alt='' />
                            </div>}
                    </div>
                    <div className={styles.paginationBox}>
                        <Pagination
                            total={userHitoriesTotal}
                            showTotal={total => `共 ${total} 条数据`}
                            current={index + 1}
                            pageSize={size}
                            onChange={this.pageChange}
                            showSizeChanger={false}
                            size='default'
                            hideOnSinglePage
                        />
                    </div>
                </div> : <div style={{textAlign:'center'}}>
                        <img src={pleaseLogin} alt='' style={{height:'150px'}} />
                        <p style={{color:'#ccc'}}>请登录后查看历史记录</p>
                        <Button style={{width:'150px'}} type='primary' onClick={this.props.goLogin}>登录</Button>
                    </div>}
            </div>
        )
    }
}