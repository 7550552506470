import React, { Component } from "react";
import { enterprise, axiosPost } from '../../utils/requestUtil';
import { Link } from 'react-router-dom'
import { Breadcrumb, Row, Col, message, Popconfirm, Empty, Input, Button } from "antd";
import { getCookieValue } from '../../utils/cookieUtil'
import { checkUser } from '../../utils/currentUser'
import { lengthToTime, timeformdate } from '../../utils/getVideoUtil'
import styles from './videoplay.module.css';

import Header from '../../components/header.js'
import Foot from '../../components/footer.js';

import noAvatar from '../../assets/images/header_no_avatar_circle.png';
import commentIcon from '../../assets/images/ic_video_count_comment.png';
import favouriteCountIcon from '../../assets/images/ic_video_count_favourite.png';
import goodCountIcon from '../../assets/images/ic_video_count_good.png';
import favouriteIcon from '../../assets/images/ic_video_favourite.png';
import goodIcon from '../../assets/images/ic_video_good.png';
import unfavouriteIcon from '../../assets/images/ic_video_un_favourite.png';
import ungoodIcon from '../../assets/images/ic_video_un_good.png';

import viewIcon from '../../assets/images/ic_video_count_view.png';
import playArt from '../../assets/images/art_play.png'

const { TextArea } = Input

export default class Videoplay extends Component {
    constructor(props) {
        super(props)
        this.listBodyBoxRef = React.createRef()
        this.videoRef = React.createRef()
        this.state = {
            contentHeight: 0,
            user: {},
            willPlayVideo: '',
            isConcerns: '0',
            videoTopics: [],
            recomments: [],
            videoComments: [],
            videoCommentsTotal: 0,
            videoCommentsIndex: 0,
            videoCommentsSize: 10,
            goodList: [],
            commentContent: '',
            videoFreeTime: 0,
            beforePlayCover: '1',
            payforPlayCover: '0'
        }
    }

    componentDidMount() {
        const contentHeight = window.innerHeight
        this.setState({
            contentHeight,
        })
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                let id = this.props.match.params.id
                this.getVideo(id)
                this.getVideoComment(id)
                this.getVideoGood(id)
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user,
                    willPlayVideo: {},
                    videoComments: [],
                    videoCommentsTotal: 0
                })
                let id = this.props.match.params.id
                this.getVideo(id)
                this.getVideoComment(id)
                this.getVideoGood(id)
            }
        })
    }

    // 获取视频基本信息
    getVideo = (id) => {
        this.videoRef.current.controls = false
        this.videoRef.current.currentTime = 0
        this.videoRef.current.pause()
        const params = {
            enterprise: enterprise,
            id: id
        }
        let token = getCookieValue('current-user').token
        axiosPost(13, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    willPlayVideo: res.data.video,
                    isConcerns: res.data.video.teacherObj.isFollow + '',
                    videoTopics: res.data.video.topics,
                    recomments: res.data.video.recomments,
                    isFavorite: res.data.video.isFavorite,
                    isGood: res.data.video.isGood,
                    beforePlayCover: '1',
                    payforPlayCover: '0'
                })
                this.listBodyBoxRef.current.scrollTop = 0

            } else {
                message.error('资源获取失败')
                this.listBodyBoxRef.current.scrollTop = 0
            }
        })
    }

    // 获取视频评论
    getVideoComment = (id) => {
        const { videoCommentsSize, videoCommentsIndex, videoComments } = this.state
        const params = {
            enterprise: enterprise,
            id: id,
            type: 1,
            index: videoCommentsIndex,
            size: videoCommentsSize
        }
        let token = getCookieValue('current-user').token
        axiosPost(20, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    videoComments: [...videoComments, ...res.data.list],
                    videoCommentsTotal: res.data.total
                })
            } else {
                this.setState({
                    videoComments: [],
                    videoCommentsTotal: 0
                })
            }
        })
    }

    // 获取视频点赞
    getVideoGood = (id) => {
        const params = {
            enterprise: enterprise,
            id: id,
            type: 0,
            index: 0,
            size: 999
        }
        let token = getCookieValue('current-user').token
        axiosPost(20, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    goodList: res.data.list
                })
            } else {
                this.setState({
                    goodList: []
                })
            }
        })
    }

    // 关注讲师
    goConcerns = (item) => {
        const { user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        const params = {
            enterprise: enterprise,
            teacher: item.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(10, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    isConcerns: '1'
                })
            }
        })
    }

    // 取消关注讲师
    cancelConcerns = (item) => {
        const params = {
            enterprise: enterprise,
            teacher: item.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(11, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    isConcerns: '0'
                })
            }
        })
    }

    // 点击收藏
    setFavourite = () => {
        const { isFavorite, willPlayVideo, user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        if (isFavorite === '0') {
            const params = {
                enterprise: enterprise,
                video: willPlayVideo.id,
            }
            let token = getCookieValue('current-user').token
            axiosPost(17, token, params, 'ArtVip', (res) => {
                if (res.code === 0) {
                    this.setState({
                        isFavorite: '1'
                    })
                }
            })
        } else {
            const params = {
                enterprise: enterprise,
                video: willPlayVideo.id,
            }
            let token = getCookieValue('current-user').token
            axiosPost(18, token, params, 'ArtVip', (res) => {
                if (res.code === 0) {
                    this.setState({
                        isFavorite: '0'
                    })
                }
            })
        }
    }

    // 点击点赞
    setGood = () => {
        const { isGood, willPlayVideo, user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        if (isGood === '0') {
            const params = {
                enterprise: enterprise,
                video: willPlayVideo.id,
                type: 0,
                content: ''
            }
            let token = getCookieValue('current-user').token
            axiosPost(15, token, params, 'ArtVip', (res) => {
                if (res.code === 0) {
                    this.setState({
                        isGood: '1'
                    })
                }
            })
        } else {
            const params = {
                enterprise: enterprise,
                id: willPlayVideo.id,
                type: 0
            }
            let token = getCookieValue('current-user').token
            axiosPost(16, token, params, 'ArtVip', (res) => {
                if (res.code === 0) {
                    this.setState({
                        isGood: '0'
                    })
                }
            })
        }
    }

    // 评论内容
    commentContent = (e) => {
        this.setState({
            commentContent: e.target.value
        })
    }

    // 提交评论
    submitComment = () => {
        const { commentContent, willPlayVideo, user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        if (!commentContent) {
            return
        }
        const params = {
            enterprise: enterprise,
            video: willPlayVideo.id,
            type: 1,
            content: commentContent
        }
        let token = getCookieValue('current-user').token
        axiosPost(15, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    commentContent: '',
                    videoCommentsIndex: 0
                }, () => this.getnewVideoComment(willPlayVideo.id))

            }
        })
    }

    // 获取当前最新所有评论
    getnewVideoComment = (id) => {
        const { videoCommentsSize, videoCommentsIndex } = this.state
        const params = {
            enterprise: enterprise,
            id: id,
            type: 1,
            index: videoCommentsIndex,
            size: videoCommentsSize
        }
        let token = getCookieValue('current-user').token
        axiosPost(20, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    videoComments: res.data.list,
                    videoCommentsTotal: res.data.total
                })
            } else {
                this.setState({
                    videoComments: [],
                    videoCommentsTotal: 0
                })
            }
        })
    }

    // 删除评论 (仅可删除自己发布的)
    commentDel = (id) => {
        const { willPlayVideo } = this.state
        const params = {
            enterprise: enterprise,
            type: 1,
            id: id
        }
        let token = getCookieValue('current-user').token
        axiosPost(16, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    videoCommentsIndex: 0
                }, () => this.getnewVideoComment(willPlayVideo.id))
            }
        })
    }

    // 获取更多评论  评论翻页 或下拉加载更多
    getMoreComment = () => {
        const { willPlayVideo, videoCommentsIndex, videoCommentsTotal, videoComments } = this.state
        if (videoComments.length === parseInt(videoCommentsTotal)) {
            return
        }
        this.setState({
            videoCommentsIndex: videoCommentsIndex + 1
        }, () => this.getVideoComment(willPlayVideo.id))
    }

    // 点击专题列表 更换正在播放的视频 重新获取视频数据
    topicsLiClick = (item) => {
        this.setState({
            videoComments: [],
            videoCommentsIndex: 0,
        }, () => {
            this.getVideo(item.id)
            this.getVideoComment(item.id)
        })
    }

    // 点击推荐视频列表 更换正在播放的视频 重新获取视频数据
    goplayVideo = (item) => {
        this.setState({
            videoComments: [],
            videoCommentsIndex: 0,
        }, () => {
            this.getVideo(item.id)
            this.getVideoComment(item.id)
        })
    }

    // 点击教师姓名 跳转到教师详情
    goTeacherDetail = () => {
        const { willPlayVideo } = this.state
        sessionStorage.setItem('teacherDetail', JSON.stringify(willPlayVideo.teacherObj))
        this.props.history.push('/home/teacherDetail')
    }

    // 开始播放 获取视频播放数据 视频是否限播时长
    startPlay = () => {
        const { willPlayVideo, user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        this.videoRef.current.play()
        this.videoRef.current.controls = true
        this.setState({
            beforePlayCover: '0'
        })
        const params = {
            enterprise: enterprise,
            video: willPlayVideo.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(14, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    videoFreeTime: res.data.freeTime
                })
            } else if (res.code === 1) {
                this.setState({
                    videoFreeTime: 0
                })
            } else {
                this.videoRef.current.pause()
                this.videoRef.current.controls = false
                message.error('视频加载失败')
            }
        })
    }

    // 视频播放中  是否限制时长 超过则暂停
    seekedVideo = (e) => {
        const { videoFreeTime } = this.state
        if (videoFreeTime > 0) {
            if (e.target.currentTime > videoFreeTime) {
                this.videoRef.current.pause()
                this.videoRef.current.controls = false
                this.setState({
                    payforPlayCover: '1'
                })
            }
        }
    }

    // 视频进度跳转  是否限制时长 超过则暂停
    videoTimeupdate = (e) => {
        const { videoFreeTime } = this.state
        if (videoFreeTime > 0) {
            if (e.target.currentTime > videoFreeTime) {
                this.videoRef.current.pause()
                this.videoRef.current.controls = false
                this.setState({
                    payforPlayCover: '1'
                })
            }
        }
    }

    // 结构生成
    topicsList = (item, index) => {
        const { willPlayVideo } = this.state
        let playVideo = willPlayVideo.playing
        let timeStr = lengthToTime(item.length)

        return <Link to={{ pathname: `/home/videoplay/${item.id}` }} key={index}>
            <li className={playVideo === index ? styles.actLi : styles.normalLi} onClick={() => this.topicsLiClick(item)}>
                <Row wrap={false} gutter={10}>
                    <Col flex='auto'>
                        <span className={styles.topicsTitle}>{index + 1}. {item.name}</span>
                    </Col>
                    <Col flex='none'>
                        <span className={styles.timeLength}>{timeStr}</span>
                    </Col>
                </Row>
            </li>
        </Link>
    }

    // 视频盒子结构
    getVideoBox = (item) => {
        let tagString = item.tags
        let tags = tagString.split(',')
        return <div className={styles.eachVideoBox}>
            <Link to={{ pathname: `/home/videoplay/${item.id}` }}>
                <div className={styles.coverImgBox} onClick={() => this.goplayVideo(item)}>
                    <img
                        alt=""
                        src={item.pictrue}
                    />
                    <div className={styles.playIconBox}>
                        <img src={playArt} alt='' />
                    </div>
                </div>
            </Link>
            <div className={styles.videoIntro}>
                <h2>{item.name}</h2>
                <div className={styles.tagsBox}>
                    {tags.map((tag, index) => <span className={styles.tag} key={index}>{tag}</span>)}
                </div>
                <span>主讲人：{item.teacherName}</span>
            </div>
        </div>
    }

    // 评论盒子结构
    commentBox = (item) => {
        const { user } = this.state
        let newTime = timeformdate(item.time)
        return <div key={item.id} className={styles.commentBox}>
            <Row wrap={false}>
                <Col flex='none'>
                    <div className={styles.commentUserImgBox}>
                        <img src={item.userImage} alt='' onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
                    </div>
                </Col>
                <Col flex='auto'>
                    <div className={styles.commentDetail}>
                        <h2>
                            {item.userName}
                            <Popconfirm placement="top" title='确定删除吗？' onConfirm={() => this.commentDel(item.id)} okText="确定" cancelText="取消">
                                <span className={styles.commentDel} style={{ display: item.user === user.key ? 'inline-block' : 'none' }}>删除</span>
                            </Popconfirm>
                        </h2>
                        <p>{item.content}</p>
                        <span>{newTime}</span>
                    </div>
                </Col>
            </Row>
        </div>
    }

    render() {
        const { isGood, isFavorite, beforePlayCover, payforPlayCover, commentContent, user, willPlayVideo, isConcerns, videoTopics, recomments, contentHeight, videoComments, videoCommentsTotal } = this.state
        return (
            <div className={styles.body} ref={this.listBodyBoxRef} style={{ height: contentHeight, minHeight: '700px' }}>
                <Header history={this.props.history} reData={this.reData} onRef={(ref) => { this.$Hearder = ref }} />
                <div className={styles.container}>
                    <div className={styles.breadcrumbBox}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Item href='/home'>首页</Breadcrumb.Item>
                            <Breadcrumb.Item>播放</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <div>
                            <Row gutter={[20, 0]}>
                                <Col span={18}>
                                    <div className={styles.videoTitilBox}>
                                        <Row wrap={false}>
                                            <Col flex='auto'>
                                                <h2>
                                                    <span className={styles.channelTag}>{willPlayVideo.channelName}</span>
                                                    <span className={styles.videoTitle} title={willPlayVideo.name}>{willPlayVideo.name}</span>
                                                </h2>
                                            </Col>
                                            <Col flex='none'>
                                                <div className={styles.goodAndFav}>
                                                    <img src={isFavorite === '0' ? unfavouriteIcon : favouriteIcon} alt='' onClick={this.setFavourite} />
                                                    <img src={isGood === '0' ? ungoodIcon : goodIcon} alt='' onClick={this.setGood} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <p>
                                            <span><img src={viewIcon} alt='' />{willPlayVideo.viewCount}</span>
                                            <span><img src={commentIcon} alt='' />{willPlayVideo.commentCount}</span>
                                            <span><img src={favouriteCountIcon} alt='' />{willPlayVideo.favouriteCount}</span>
                                            <span><img src={goodCountIcon} alt='' />{willPlayVideo.goodCount}</span>
                                            <span className={styles.playvideoIntro}>简介：{willPlayVideo.intro}</span>
                                        </p>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={styles.videoTeacherBox}>
                                        <Row wrap={false}>
                                            <Col flex='none'>
                                                <div className={styles.teacherImg} onClick={this.goTeacherDetail}>
                                                    <img src={willPlayVideo.teacherImage} alt='' />
                                                </div>
                                            </Col>
                                            <Col flex='auto'>
                                                <div className={styles.teacherIntro}>
                                                    <h2 onClick={this.goTeacherDetail}>{willPlayVideo.teacherName}</h2>
                                                    <p>{willPlayVideo.teacherObj ? willPlayVideo.teacherObj.orgName : ''}</p>
                                                    {
                                                        isConcerns === '0' ? <Popconfirm onConfirm={() => this.goConcerns(willPlayVideo.teacherObj)} title="确定关注吗？" okText="确认" cancelText="取消">
                                                            <span className={styles.noconcerns}>关注</span>
                                                        </Popconfirm> : <Popconfirm onConfirm={() => this.cancelConcerns(willPlayVideo.teacherObj)} title="确定取消关注吗？" okText="确认" cancelText="取消">
                                                                <span className={styles.isconcerns}>已关注</span>
                                                            </Popconfirm>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.videoBody}>
                            <Row gutter={[20, 0]}>
                                <Col span={18}>
                                    <div className={styles.playVideoBox}>
                                        <video
                                            ref={this.videoRef}
                                            className={styles.playVideo}
                                            onPlay={this.playVideo}
                                            onSeeked={this.seekedVideo}
                                            onPause={this.stopVideo}
                                            controls={false}
                                            controlsList="nodownload"
                                            poster={willPlayVideo.pictrue}
                                            src={willPlayVideo.url}
                                            onTimeUpdate={this.videoTimeupdate}
                                        >
                                        </video>
                                        <div className={styles.playIcon} style={{ display: beforePlayCover === '0' ? 'none' : 'block' }} onClick={this.startPlay}>
                                            <img src={playArt} alt='' />
                                        </div>
                                        <div className={styles.payContinuePlay} style={{ display: payforPlayCover === '0' ? 'none' : 'block' }}>
                                            <p>需要购买该视频或开通VIP才能继续观看</p>
                                            <Button type='primary'>{user.type && user.type !== '-1' ? '开通会员' : '登录账号'}</Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={styles.topicsBox}>
                                        <ul>
                                            {videoTopics.map((item, index) =>
                                                this.topicsList(item, index)
                                            )}
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.videoRecommentsBox}>
                            <h2>相关推荐</h2>
                            <Row gutter={[30, 30]}>
                                {recomments.map(item => <Col span={6} key={item.id}>
                                    {this.getVideoBox(item)}
                                </Col>)}
                            </Row>
                        </div>
                        <div className={styles.videoCommentsBox}>
                            <h2>评论{videoCommentsTotal}</h2>
                            <Row wrap={false}>
                                <Col flex='none'>
                                    <div className={styles.commentUserImgBox}>
                                        <img src={user.image} alt='' onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
                                    </div>
                                </Col>
                                <Col flex='auto'>
                                    <div className={styles.commentDetail}>
                                        <TextArea rows={3} value={commentContent} onChange={this.commentContent} />
                                        <Button onClick={this.submitComment} type='primary' style={{ margin: '10px 0 15px 0' }}>发表评论</Button>
                                    </div>
                                </Col>
                            </Row>
                            {videoComments.length > 0 ? videoComments.map(item => this.commentBox(item)) : <Empty />}
                            <p className={styles.moreComments} onClick={this.getMoreComment}>
                                {videoComments.length === parseInt(videoCommentsTotal) ? '没有更多数据' : '查看更多评论'}
                            </p>
                        </div>
                    </div>
                </div>
                <Foot />
            </div>
        )
    }
}
