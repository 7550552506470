import React, { Component } from "react";
import { Row, Col, Input, Button } from 'antd'
import { axiosPost, enterprise } from '../../../utils/requestUtil'
import { getCookieValue, setCookieValue, removeCookieValue } from '../../../utils/cookieUtil'
import { checkUser } from '../../../utils/currentUser'
import styles from './userBuy.module.css'

import noRecord from '../../../assets/images/pic_no_record.png';
import pleaseLogin from '../../../assets/images/user_center_pleaseLogin.png';

const { Search } = Input

export default class UserBuy extends Component {
    constructor() {
        super()
        this.state = {
            user: {},
            keyword: '',
            userBuy: [],
            index: 0,
            size: 10
        }
    }

    componentDidMount() {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getUserBuy()
            }
        })
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    searchBuy = (e) => {
        this.setState({
            keyword: e,
            index: 0
        }, () => this.getUserBuy())
    }

    getUserBuy = () => {
        // const { keyword, index, size } = this.state
        // let token = getCookieValue('current-user').token
        // const params = {
        //     enterprise: enterprise,
        //     keyword: keyword,
        //     index: index,
        //     size: size
        // }
        // axiosPost(29, token, params, 'ArtVip', (res) => {
        //     console.log(res)
        // })
    }

    render() {
        const { user, userBuy } = this.state
        return (
            <div>
                {user.type && user.type !== '-1' ? <div>
                    <div style={{ marginBottom: '20px' }}>
                        <Row wrap={false}>
                            <Col flex='none'>
                                <span className={styles.historyTitle}>已购资源</span>
                            </Col>
                            <Col flex='auto'>
                                <Search style={{ width: '50%' }} placeholder='输入关键字搜索' onSearch={this.searchBuy} enterButton />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        {userBuy.length > 0 ? <Row gutter={[30, 30]}>
                            {userBuy.map(item => <Col sm={12} md={8} lg={8} xl={8} xxl={6} key={item.id}>
                                {this.eachHistory(item)}
                            </Col>)}
                        </Row> : <div className={styles.noDataBox}>
                                {/* 暂无历史，赶快去学习视频吧 */}
                                <img src={noRecord} alt='' />
                            </div>}
                    </div>
                    <div className={styles.paginationBox}>
                        {/* <Pagination
                            total={userHitoriesTotal}
                            showTotal={total => `共 ${total} 条数据`}
                            current={index + 1}
                            pageSize={size}
                            onChange={this.pageChange}
                            showSizeChanger={false}
                            size='default'
                            hideOnSinglePage
                        /> */}
                    </div>
                </div> : <div style={{ textAlign: 'center' }}>
                        <img src={pleaseLogin} alt='' style={{ height: '150px' }} />
                        <p style={{ color: '#ccc' }}>请登录后查看已购买视频</p>
                        <Button style={{ width: '150px' }} type='primary' onClick={this.props.goLogin}>登录</Button>
                    </div>}
            </div>
        )
    }
}