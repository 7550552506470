import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { Row, Col, message, Popover } from 'antd'
// import { enterprise, axiosPost } from '../../utils/requestUtil'
import { getCookieValue, setCookieValue, removeCookieValue } from '../utils/cookieUtil'
import { checkUser } from '../utils/currentUser'
import styles from './header.module.css'

import Login from './login.js'

import noAvatar from '../assets/images/header_no_avatar_circle.png';
import logoImg from '../assets/images/LOGO.png';
import logoImg2 from '../assets/images/LOGO2.png';
import cateImg from '../assets/images/header_cate.png';
import searchIcon from '../assets/images/header_search.png';
import headerIsBuy from '../assets/images/ic_person_order.png'
import headerMyHistory from '../assets/images/ic_person_history.png'
import headerMyCollect from '../assets/images/ic_person_collect.png'
import headerMyFocus from '../assets/images/ic_person_focus_on.png'
import headerCircle from '../assets/images/ic_friend_group.png'

export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
            goLogin: '0',
            searchIpt: '',
            windowWidth: 0
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        let windowWidth = window.innerWidth
        this.setState({
            windowWidth,
        })
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
            }
        })
        window.addEventListener('resize', this.resize)
    }

    resize = () => {
        this.setState({
            windowWidth: window.innerWidth
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    loginClose = (flag) => {
        if (flag === 'login') {
            checkUser(res => {
                if (res.code === 0) {
                    message.destroy()
                    message.success('登录成功', 1)
                    this.setState({
                        user: res.user,
                        goLogin: '0'
                    })
                    // 执行操作
                    this.props.reData()
                }
            })
        } else {
            this.setState({
                goLogin: '0'
            })
        }
    }

    outLogin = () => {
        removeCookieValue('current-user')
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                // 执行操作
                this.props.reData()
            }
        })
    }

    checkenter = (e) => {
        if (e.charCode === 13) {
            this.setSession()
        }
    }

    setSession = () => {
        const { searchIpt } = this.state
        if (this.props.history.location.pathname === '/home/videoLists') {
            this.props.searchIpt(searchIpt)
        } else {
            if (searchIpt.trim() === '') {
                message.destroy()
                message.warning('搜索内容为空', 1)
                return
            }
            sessionStorage.setItem('search-video', JSON.stringify({ keyword: searchIpt }))
            this.goSearch()
        }
    }

    goSearch = () => {
        this.props.history.push('/home/videoLists')
    }

    searchIptChange = (e) => {
        this.setState({
            searchIpt: e.target.value
        })
    }

    // 点击登录
    goLogin = () => {
        this.setState({
            goLogin: '1'
        })
    }

    // 头部列表跳转到 视频列表
    goVideoList = () => {
        sessionStorage.removeItem('search-video')
        this.props.history.push('/home/videoLists')
    }

    // 跳转到讲师列表
    goTeacherList = () => {
        this.props.history.push('/home/teacherList')
    }

    // 跳转到字帖
    goCopybook = () => {
        this.props.history.push('/home/copybook')
    }

    // 跳转到文章列表
    goArticleList = () => {
        this.props.history.push('/home/articleList')
    }

    // 跳转用户中心
    goUsercenter = (flag) => {
        setCookieValue('centerFlag', flag)

        if (this.props.history.location.pathname === '/home/usercenter') {
            this.props.goUsercenter(flag)
        } else {
            this.props.history.push('/home/usercenter')
        }
    }

    // 跳转到圈子
    goFriendCircle = () => {
        this.props.history.push('/home/circle')
    }

    render() {
        const { user, goLogin, windowWidth } = this.state

        const text = <div className={styles.headerCenterTitleBox}>
            <Row wrap={false}>
                <Col flex='auto'>
                    <div className={styles.headerCenterLeft}>
                        <Row>
                            <Col>
                                <div className={styles.headerCenterUserImg}>
                                    <img src={user.image} alt='' onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
                                </div>
                            </Col>
                            <Col>
                                <div className={styles.headerCenterUserIntro}>
                                    <h2 title={user.name}>{user.name}</h2>
                                    <span>积分：0</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col flex='none'>
                    <div className={styles.headerCenterRight}><span onClick={() => this.goUsercenter('default')}>进入个人中心 &gt;&gt;</span></div>
                </Col>
            </Row>

        </div>
        const content = <div className={styles.headerCenterContent}>
            <Row>
                <Col span={6}>
                    <div className={styles.headerCenterCate} onClick={() => this.goUsercenter('myBuy')}>
                        <img src={headerIsBuy} alt='' />
                        <h2>已购视频</h2>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.headerCenterCate} onClick={() => this.goUsercenter('default')}>
                        <img src={headerMyHistory} alt='' />
                        <h2>观看历史</h2>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.headerCenterCate} onClick={() => this.goUsercenter('myCollect')}>
                        <img src={headerMyCollect} alt='' />
                        <h2>我的收藏</h2>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.headerCenterCate} onClick={() => this.goUsercenter('myFocus')}>
                        <img src={headerMyFocus} alt='' />
                        <h2>我的关注</h2>
                    </div>
                </Col>
            </Row>
            <div className={styles.outLogin}>
                <span onClick={this.outLogin}>退出登录</span>
            </div>
        </div>
        const cateContent = <div className={styles.headerFenlei}>
            <ul>
                <li onClick={this.goVideoList}>视频列表</li>
                <li onClick={this.goTeacherList}>讲师列表</li>
                <li onClick={this.goCopybook}>字帖</li>
                <li onClick={this.goArticleList}>文章</li>
            </ul>
        </div>
        return (
            <div className={styles.body}>
                <header className={styles.headerBox}>
                    <Row wrap={false} gutter={[60, 0]}>
                        <Col flex='none'>
                            <div className={styles.logoImgBox}>
                                <Link to={{ pathname: '/home' }}>
                                    <img src={windowWidth > 1500 ? logoImg : logoImg2} alt='' />
                                </Link>
                            </div>
                        </Col>
                        <Col flex='none'>
                            <Popover title={false} content={cateContent} placement="bottom">
                                <div className={styles.cateBox}>
                                    <img src={cateImg} alt='' />
                                    <span>列表</span>
                                </div>
                            </Popover>
                        </Col>
                        <Col flex='none'>
                            <div className={styles.circleBox} onClick={this.goFriendCircle} title='圈子'>
                                <img src={headerCircle} alt='' />
                            </div>
                        </Col>
                        <Col flex='auto'>
                            <div className={styles.searchBox}>
                                <input onChange={this.searchIptChange} onKeyPress={this.checkenter} />
                                <div className={styles.searchIconBox} onClick={this.setSession}>
                                    <img src={searchIcon} alt='' />
                                </div>
                            </div>
                        </Col>
                        <Col flex='none'>
                            {
                                user.type && user.type !== '-1' ? <div className={styles.userBox}>
                                    <Popover placement="bottomRight" title={text} content={content}>
                                        <img src={user.image} alt='' onClick={() => this.goUsercenter('default')} onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
                                    </Popover>
                                    {/* <span>{user.name}</span> */}
                                </div> : <div className={styles.loginButton} onClick={this.goLogin}>
                                    <span type='primary'><span style={{ marginRight: '10px' }}>登</span><span>录</span></span>
                                </div>
                            }
                        </Col>
                    </Row>
                </header>
                <Login isLogin={goLogin} history={this.props.history} closeClick={this.loginClose} />
            </div>
        )
    }
}