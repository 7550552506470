import React, { Component } from 'react'
import { Breadcrumb, Row, Col } from 'antd'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'

import styles from './articleDetail.module.css'

import Header from '../../components/header.js'

export default class ArticleDetail extends Component {
    constructor() {
        super()
        this.state = {
            contentHeight: 0,
            article: {},
            url: ''
        }
    }

    componentDidMount() {
        let article = JSON.parse(sessionStorage.getItem('actArt'))
        const contentHeight = window.innerHeight
        this.setState({
            contentHeight: contentHeight
        })
        this.setState({
            article: article,
            url: article.url,
            contentHeight: contentHeight
        })
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {

    }

    render() {
        const { article, contentHeight, url } = this.state
        return (
            <div>
                <Header history={this.props.history} reData={this.reData} />
                <div className={styles.container}>
                    {/* <iframe width='100%' height='1000px' title='111' src={url}></iframe> */}
                    {/* <iframe style={{width:'800px',height:'600px'}} src={url}/> */}
                </div>
            </div >
        )
    }
}