import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { Row, Col, Popconfirm, Pagination } from 'antd';
import { enterprise, axiosPost } from '../../utils/requestUtil';
import { getCookieValue } from '../../utils/cookieUtil'
import { checkUser } from '../../utils/currentUser'
import { getVideos } from '../../utils/getVideoUtil'
import styles from './teacherDetail.module.css'

import Header from '../../components/header.js'
import Foot from '../../components/footer.js';

import noAvatar from '../../assets/images/header_no_avatar_circle.png';
import playArt from '../../assets/images/art_play.png'

export default class TeacherDetail extends Component {
    constructor() {
        super()
        this.bodyRef = React.createRef()
        this.state = {
            contentHeight: 0,
            user: {},
            videoIndex: 0,
            videoSize: 20,
            teacher: {},
            teacherArt: [],
            videoTotal: 0,
            isFollow: '',
        }
    }

    componentDidMount() {
        const contentHeight = window.innerHeight
        this.setState({
            contentHeight,
        })
        this.bodyRef.current.scrollTop = 0
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getTeacherDetail()
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getTeacherDetail()
            }
        })
    }

    // 获取讲师详情 无需网络请求 上个页面跳转时存入 sessionStorage
    getTeacherDetail = () => {
        let teacher = JSON.parse(sessionStorage.getItem('teacherDetail'))
        this.setState({
            teacher: teacher,
            isFollow: teacher.isFollow
        })
        this.getTeacherVideos(teacher.id)
    }

    // 获取讲师的所有视频作品
    getTeacherVideos = (id) => {
        const { videoIndex, videoSize } = this.state
        this.bodyRef.current.scrollTop = 0
        const require = {
            teacher: id,
            index: videoIndex,
            size: videoSize
        }
        getVideos(require, (res) => {
            if (res.code === 0) {
                this.setState({
                    teacherArt: res.data.list,
                    videoTotal: res.data.total
                })
            } else {
                this.setState({
                    teacherArt: [],
                    videoTotal: 0
                })
            }
        })
    }

    // 关注讲师
    goFollow = (item) => {
        const { user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        const params = {
            enterprise: enterprise,
            teacher: item.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(10, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    isFollow: '1'
                })
            }
        })
    }

    // 取消关注讲师
    goDisFollow = (item) => {
        const params = {
            enterprise: enterprise,
            teacher: item.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(11, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    isFollow: '0'
                })
            }
        })
    }

    // 讲师作品翻页
    pageChange = (e) => {
        const { teacher } = this.state
        this.setState({
            videoIndex: e - 1
        }, () => this.getTeacherVideos(teacher.id))
    }

    // 结构函数
    videoBox = (item) => {
        let tagString = item.tags
        let tags = tagString.split(',')
        return <Row>
            <Col span={12}>
                <Link target='_blank' to={{ pathname: `/home/videoplay/${item.id}` }}>
                    <div className={styles.videoImgBox}>
                        <img src={item.pictrue} alt='' onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
                        <div className={styles.playIconBox}>
                            <img src={playArt} alt='' />
                        </div>
                    </div>
                </Link>
            </Col>
            <Col span={12}>
                <div className={styles.videoIntro}>
                    <h2>{item.name}</h2>
                    <p>{item.intro === '' ? '暂无描述' : item.intro}</p>
                    <div className={styles.tagsBox}>
                        {tags.map((tag, index) => <span className={styles.tag} key={index}>{tag}</span>)}
                    </div>
                    <span>专题：{item.topicName}</span>
                </div>
            </Col>
        </Row>
    }


    render() {
        const { teacher, isFollow, teacherArt, videoTotal, videoIndex, videoSize, contentHeight } = this.state
        return (
            <div ref={this.bodyRef} style={{ height: contentHeight, overflow: 'auto' }}>
                <Header history={this.props.history} reData={this.reData} onRef={(ref) => { this.$Hearder = ref }} />
                <div className={styles.container}>
                    <div className={styles.teacherPersonBox}>
                        <Row wrap={false}>
                            <Col flex='none'>
                                <div className={styles.teacherImgBox}>
                                    <img src={teacher.image} alt='' onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
                                </div>
                            </Col>
                            <Col flex='auto'>
                                <div className={styles.teacherIntroBox}>
                                    <div className={styles.teacherIntroTitle}>
                                        <h2>{teacher.name}<span>{teacher.orgName}</span></h2>
                                        {
                                            isFollow + '' === '0' ? <Popconfirm onConfirm={() => this.goFollow(teacher)} title="确定关注吗？" okText="确认" cancelText="取消">
                                                <span className={styles.noconcerns} onClick={(e) => { e.stopPropagation() }}>+ 关注</span>
                                            </Popconfirm> : <Popconfirm onConfirm={() => this.goDisFollow(teacher)} title="确定取消关注吗？" okText="确认" cancelText="取消">
                                                    <span className={styles.isconcerns} onClick={(e) => { e.stopPropagation() }}>已关注</span>
                                                </Popconfirm>
                                        }
                                    </div>
                                    <p className={styles.teacherIntroDet}>
                                        {teacher.intro}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.teacherArtBox}>
                        <div className={styles.tuijianArt}>
                            <h2>精选推荐</h2>
                            <div>
                                <Row>
                                    <Col span={8}>
                                        {teacherArt[0] ? <div className={styles.borderBox}>
                                            {this.videoBox(teacherArt[0])}
                                        </div> : ''}

                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className={styles.allArt}>
                            <h2>全部视频</h2>
                            <div>
                                <Row gutter={[30, 30]}>
                                    {teacherArt.map(item => <Col span={12} key={item.id}>
                                        <div className={styles.borderBox}>
                                            {this.videoBox(item)}
                                        </div>
                                    </Col>)}
                                </Row>
                            </div>
                            <div className={styles.paginationBox}>
                                <Pagination
                                    total={videoTotal}
                                    showTotal={total => `共 ${total} 条数据`}
                                    current={videoIndex + 1}
                                    pageSize={videoSize}
                                    onChange={this.pageChange}
                                    showSizeChanger={false}
                                    size='default'
                                    hideOnSinglePage
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Foot />
            </div>
        )
    }
}