import React, { Component } from 'react';

export default class Qa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            qa: this.props.qa
        }
    }

    render() {
        const { qa } = this.state
        return (
            <div>
                <iframe src={qa} width='100%' height='500px' frameBorder='0' title='帮助'>

                </iframe>
            </div>
        )
    }
}