import React, { Component } from "react";
import { Row, Col, Input, Alert, Popconfirm, Pagination } from 'antd';
import { enterprise, axiosPost } from '../../utils/requestUtil';
import { getCookieValue } from '../../utils/cookieUtil'
import { checkUser } from '../../utils/currentUser'
import styles from "./teacherList.module.css";

import Header from '../../components/header.js'
import Foot from '../../components/footer.js';

import noAvatar from '../../assets/images/header_no_avatar_circle.png';

const { Search } = Input

export default class TeacherList extends Component {
    constructor() {
        super()
        this.state = {
            contentHeight: 0,
            user: {},
            teacherList: [],
            keyword: '',
            index: 0,
            size: 24,
            teacherTotal: 0
        }
    }

    componentDidMount() {
        const contentHeight = window.innerHeight
        this.setState({
            contentHeight,
        })
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getTeacherList()
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getTeacherList()
            }
        })
    }

    // 获取讲师列表
    getTeacherList = () => {
        const { index, size, keyword } = this.state
        const params = {
            enterprise: enterprise,
            keyword: keyword,
            index: index,
            size: size
        }
        let token = getCookieValue('current-user').token
        axiosPost(9, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    teacherList: res.data.list,
                    teacherTotal: res.data.total
                })
            } else {
                this.setState({
                    teacherList: [],
                    teacherTotal: 0
                })
            }
        })
    }

    // 点击讲师 跳转到讲师详情
    goTeacherDetail = (item) => {
        sessionStorage.setItem('teacherDetail', JSON.stringify(item))
        this.props.history.push('/home/teacherDetail')
    }

    // 关注讲师
    goFollow = (e, item) => {
        e.stopPropagation()
        const { user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        const params = {
            enterprise: enterprise,
            teacher: item.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(10, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.getTeacherList()
            }
        })
    }

    // 取消关注讲师
    goDisFollow = (e, item) => {
        e.stopPropagation()
        const params = {
            enterprise: enterprise,
            teacher: item.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(11, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.getTeacherList()
            }
        })
    }

    // 翻页操作
    pageChange = (e) => {
        this.setState({
            index: e - 1
        }, () => this.getTeacherList())
    }

    // 讲师卡片
    teacherCard = (item) => {
        return <div className={styles.teacherBox} onClick={() => this.goTeacherDetail(item)}>
            <div className={styles.teacherImgBox}>
                <img src={item.image} alt='' onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
            </div>
            <div className={styles.teacherIntro}>
                <h2>{item.name}</h2>
                <p>{item.intro}</p>
                <div className={styles.isfollowBox}>
                    <span>作品数：{item.videoCount}</span>
                    {
                        item.isFollow + '' === '0' ? <Popconfirm onConfirm={(e) => this.goFollow(e, item)} title="确定关注吗？" okText="确认" cancelText="取消">
                            <span className={styles.noconcerns} onClick={(e) => { e.stopPropagation() }}>+ 关注</span>
                        </Popconfirm> : <Popconfirm onConfirm={(e) => this.goDisFollow(e, item)} title="确定取消关注吗？" okText="确认" cancelText="取消">
                                <span className={styles.isconcerns} onClick={(e) => { e.stopPropagation() }}>已关注</span>
                            </Popconfirm>
                    }
                </div>
            </div>
        </div>
    }

    // 关键字搜索讲师
    onSearch = (e) => {
        this.setState({
            keyword: e,
            index: 0
        }, () => this.getTeacherList())
    }


    render() {
        const { teacherList, index, size, teacherTotal } = this.state
        return (
            <div>
                <Header history={this.props.history} reData={this.reData} onRef={(ref) => { this.$Hearder = ref }} />
                <div className={styles.container}>
                    <div style={{ padding: '20px 0' }}>
                        <Row gutter={[30, 30]}>
                            <Col span={12}>
                                <Search
                                    placeholder='请输入关键字搜索讲师'
                                    allowClear
                                    enterButton="搜索"
                                    size="large"
                                    onSearch={this.onSearch}
                                />
                            </Col>
                            <Col span={12}>
                                <Alert message='点击讲师可查看该讲师详情' type="info" showIcon />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={[30, 30]}>
                            {teacherList.map(item => <Col span={6} key={item.id}>
                                {this.teacherCard(item)}
                            </Col>)}
                        </Row>
                    </div>
                    <div className={styles.paginationBox}>
                        <Pagination
                            total={teacherTotal}
                            showTotal={total => `共 ${total} 条数据`}
                            current={index + 1}
                            pageSize={size}
                            onChange={this.pageChange}
                            showSizeChanger={false}
                            size='default'
                            hideOnSinglePage
                        />
                    </div>
                </div>
                <Foot />
            </div>
        )
    }
}