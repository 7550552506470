import React, { Component } from 'react'
import { Carousel, Row, Col, Timeline, message, Card } from 'antd'
import { Link } from 'react-router-dom'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'
import { getVideos } from "../../utils/getVideoUtil";
import styles from './homeCom.module.css'

import Header from '../../components/header.js'
import Foot from '../../components/footer.js';

import bgiimg from '../../assets/images/banner.png'
import noAvatar from '../../assets/images/header_no_avatar_circle.png';
import playArt from '../../assets/images/art_play.png'
import gomoreImg from '../../assets/images/gomore.png'
import courseImg from '../../assets/images/home_course.png'
import courseUpImg from '../../assets/images/home_courseUp.png'
import exportImg from '../../assets/images/home_export.png'
import onlineImg from '../../assets/images/home_online.png'

const onlineLists = []
for (let i = 0; i < 11; i++) {
    onlineLists.push({
        key: i,
        title: '中国书法基础篇中国书法基础篇中国书法基础篇' + i,
        time: '08:08'
    })
}

export default class HomeCom extends Component {
    constructor() {
        super()
        this.state = {
            user: {},
            banners: [],
            channels: [],
            actChannel: '',
            topics: [],
            videos: [],
            functions: [],
            articalCate: [],
            actArticalCateId: '',
            articals: []
        }
    }

    componentDidMount() {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getHomeData()
                this.getFunctions()
                this.getArticalCate()
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getHomeData()
                this.getFunctions()
                this.getArticalCate()
            }
        })
    }

    // 获取首页数据
    getHomeData = () => {
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise
        }
        axiosPost(5, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    banners: res.data.banners,
                    channels: res.data.channels,
                    actChannel: res.data.channels[0].id,
                    topics: res.data.topics,
                }, () => this.getVideo())
            }
        })
    }

    // 获取功能
    getFunctions = () => {
        const params = {
            enterprise: enterprise
        }
        let token = getCookieValue('current-user').token
        axiosPost(40, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    functions: res.data.list
                })
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }

    // 获取文章分类
    getArticalCate = () => {
        const params = {
            enterprise: enterprise
        }
        let token = getCookieValue('current-user').token
        axiosPost(42, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    articalCate: res.data.list,
                    actArticalCateId: res.data.list[0].id
                }, () => this.getArticals())
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }

    // 获取文章列表
    getArticals = () => {
        const { actArticalCateId } = this.state
        const params = {
            enterprise: enterprise,
            channel: actArticalCateId,
            index: 0,
            size: 4
        }
        let token = getCookieValue('current-user').token
        axiosPost(43, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    articals: res.data.list
                })
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }

    // 改变文章类别  获取新的数据
    artCateChange = (id) => {
        this.setState({
            actArticalCateId: id
        }, () => this.getArticals())
    }

    // 跳转到文章列表
    goArticleList = () => {
        const { articalCate } = this.state
        sessionStorage.setItem('articleCate', JSON.stringify(articalCate))
        this.props.history.push('/home/articleList')
    }

    // 获取首页推荐视频
    getVideo = () => {
        const { actChannel } = this.state
        const require = {
            channel: actChannel,
            size: 8
        }
        getVideos(require, (res) => {
            if (res.code === 0) {
                this.setState({
                    videos: res.data.list
                })
            }
        })
    }

    // 首页分类改变  获取改变后的视频
    changeChannel = (id) => {
        this.setState({
            actChannel: id
        }, () => { this.getVideo() })
    }

    // 查看更多  跳转到视频列表页面
    gomore = (flag) => {
        const { actChannel } = this.state
        if (flag === 'channel') {
            if (actChannel === '-1') {
                sessionStorage.setItem('search-video', JSON.stringify({ channel: '' }))
            } else {
                sessionStorage.setItem('search-video', JSON.stringify({ channel: actChannel }))
            }
        }
        if (flag === 'topic') {
            sessionStorage.setItem('search-video', JSON.stringify({ topic: '' }))
        }
        this.props.history.push('/home/videoLists')
    }

    // 查看专题视频 跳转到视频列表页面 并筛选出该专题的视频
    topicGoMore = (item) => {
        sessionStorage.setItem('search-video', JSON.stringify({ topic: item.id }))
        this.props.history.push('/home/videoLists')
    }

    // 结构生成函数
    // 轮播图
    getCarousel = () => {
        const { banners } = this.state
        if (banners.length > 0) {
            return <Carousel autoplay dotPosition='bottom'>
                {banners.map((item, index) => <div key={index} className={styles.carousel}>
                    <h3>
                        <img src={item.url} alt='' />
                    </h3>
                </div>)}
            </Carousel>
        }
        if (banners.length === 0) {
            return <div className={styles.carousel}></div>
        }
    }

    // 分类视频盒子
    getVideoBox = (item) => {
        let tagString = item.tags
        let tags = tagString.split(',')
        return <div className={styles.eachVideoBox}>
            <Link target='_blank' to={{ pathname: `/home/videoplay/${item.id}` }}>
                <div className={styles.coverImgBox}>
                    <img
                        alt=""
                        src={item.pictrue}
                    />
                    <div className={styles.playIconBox}>
                        <img src={playArt} alt='' />
                    </div>
                </div>
            </Link>
            <div className={styles.videoIntro}>
                <h2>{item.name}</h2>
                <div className={styles.tagsBox}>
                    {tags.map((tag, index) => <span className={styles.tag} key={index}>{tag}</span>)}
                </div>
                <span>主讲人：{item.teacherName}</span>
            </div>
        </div>
    }

    // 专题盒子
    getTopicBox = (item) => {
        return <Row className={styles.eachTopicBox} onClick={() => this.topicGoMore(item)}>
            <Col span={12}>
                <div className={styles.topicCoverBox}>
                    <img src={item.pageImage} alt='' />
                </div>
            </Col>
            <Col span={12}>
                <div className={styles.topicIntroBox}>
                    <h2>{item.name}</h2>
                    <p>{item.intro}</p>
                    <span>讲师：{item.teacherName}</span>
                </div>
            </Col>
        </Row>
    }

    // 文章作品列表
    getArticalBox = (item) => {
        return <div className={styles.articlsLiBox}>
            <Card
                hoverable
                style={{ width: '100%' }}
                cover={<img alt='封面图片加载失败' src={item.image} className={styles.liCoverImg} />}
            >
                <p className={styles.articleTitle} title={item.title}>{item.title}</p>
                <span className={styles.articleWriter}>作者：{item.writer}</span>
            </Card>
        </div>
    }

    render() {
        const { channels, actChannel, videos, topics, functions, articals, articalCate, actArticalCateId } = this.state
        return (
            <div>
                <Header history={this.props.history} reData={this.reData} />
                <div>
                    {this.getCarousel()}
                </div>
                <div className={styles.container}>
                    {/* 直播模块 */}
                    {/* <div className={styles.homeOnline}>
                        <h2 className={styles.homeTitle}>
                            <span>最近直播马上看</span>
                            <div className={styles.bottomline}></div>
                        </h2>
                        <div className={styles.onlineLists}>
                            <Row>
                                <Col span={12}>
                                    <div className={styles.listLeft} style={{ backgroundImage: `url(${bgiimg})` }}>
                                        <div className={styles.listLeftContent}>
                                            <h2>高科技与学习书法</h2>
                                            <p>距离上课<span>1</span>天</p>
                                            <span>立即报名</span>
                                            <div className={styles.onlineIntro}>
                                                <Row wrap={false}>
                                                    <Col flex="none">
                                                        <div className={styles.onlineTeacherName}>
                                                            <img src={noAvatar} alt='' />
                                                            <span>讲师：杨正寿</span>
                                                        </div>
                                                    </Col>
                                                    <Col flex="auto">
                                                        <div className={styles.onlineTeacherOrg}>合云书画高级讲师 (书法-绘画-视觉设计)</div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className={styles.listRight}>
                                        <Timeline>
                                            {onlineLists.map(item => <Timeline.Item key={item.key}>
                                                <span style={{ marginRight: '15px' }}>{item.time}</span><span>{item.title}</span>
                                            </Timeline.Item>)}
                                        </Timeline>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div> */}
                    {/* 功能列表 */}
                    <div className={styles.functionBox}>
                        <h2 className={styles.homeTitle}>
                            <span>功能</span>
                            <div className={styles.bottomline}></div>
                        </h2>
                        <div>
                            <Row>
                                {functions.map(item => <Col key={item.id} span={8}>
                                    <div className={styles.funcLiBox}>
                                        <img src={item.icon} alt='' />
                                        <h2>{item.name}</h2>
                                    </div>
                                </Col>)}
                            </Row>
                        </div>
                    </div>
                    {/* 文章作品 */}
                    <div className={styles.articleBox}>
                        <h2 className={styles.homeTitle}>
                            <span>文章作品</span>
                            <div className={styles.bottomline}></div>
                        </h2>
                        <div className={styles.articalContentBox}>
                            <div className={styles.articalCateBox}>
                                <ul>
                                    {articalCate.map(item => <li key={item.id} onClick={() => this.artCateChange(item.id)}>
                                        {item.name}
                                        <div className={styles.artCateBottomline} style={{ display: actArticalCateId === item.id ? 'block' : 'none' }}></div>
                                    </li>)}
                                    <li onClick={this.goArticleList}>
                                        查看全部&gt;&gt;
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.articalList}>
                                <Row gutter={[20, 20]}>
                                    {
                                        articals.length > 0 ? articals.map(item => <Col span={6} key={item.id}>
                                            <a href={item.url} target='_blank' rel='noreferrer'>
                                                {this.getArticalBox(item)}
                                            </a>
                                        </Col>) : ''
                                    }
                                </Row>
                            </div>
                        </div>
                    </div>
                    {/* 分类模块 */}
                    <div className={styles.homeChannels}>
                        <h2 className={styles.homeTitle}>
                            <span>分类</span>
                            <div className={styles.bottomline}></div>
                            <p onClick={() => this.gomore('channel')}>更多<img src={gomoreImg} alt='' /></p>
                        </h2>
                        <div className={styles.channelsBox}>
                            <Row wrap={false}>
                                <Col flex="none">
                                    <div className={styles.cateTitle}>
                                        <span>课程类别</span>
                                    </div>
                                </Col>
                                <Col flex="auto">
                                    <div className={styles.cateLists}>
                                        {channels.map(item => <div key={item.id} className={styles.channelBox} onClick={() => this.changeChannel(item.id)}>
                                            <span>{item.name}</span>
                                            <div style={{ display: actChannel === item.id ? 'block' : 'none' }} className={styles.bottomline}></div>
                                        </div>)}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <Row gutter={[30, 30]}>
                                {videos.map(item => <Col span={6} key={item.id}>
                                    {this.getVideoBox(item)}
                                </Col>
                                )}
                            </Row>
                        </div>
                    </div>
                    {/* 专题模块 */}
                    <div className={styles.homeTopics}>
                        <h2 className={styles.homeTitle}>
                            <span>专题</span>
                            <div className={styles.bottomline}></div>
                            <p onClick={() => this.gomore('topic')}>更多<img src={gomoreImg} alt='' /></p>
                        </h2>
                        <div className={styles.topicsBox}>
                            <Row gutter={[30, 0]}>
                                {topics.map(item => <Col span={8} key={item.id}>
                                    {this.getTopicBox(item)}
                                </Col>)}
                            </Row>
                        </div>
                    </div>
                    {/* 更多 */}
                    <div className={styles.cludemore}>
                        <span>查看合云书画易书考课程类目1000门精品课程</span><img src={gomoreImg} alt='' />
                    </div>
                    <div className={styles.cludemore2}>
                        <Row>
                            <Col span={6}>
                                <div className={styles.cludemore2Each}>
                                    <img src={courseUpImg} alt='' />
                                    <p>课程升级</p>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className={styles.cludemore2Each}>
                                    <img src={onlineImg} alt='' />
                                    <p>在线直播</p>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className={styles.cludemore2Each}>
                                    <img src={courseImg} alt='' />
                                    <p>精品课程</p>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className={styles.cludemore2Each}>
                                    <img src={exportImg} alt='' />
                                    <p>名家天地</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Foot />
            </div >
        )
    }
}