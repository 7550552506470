import React, { Component } from 'react'
import { Row, Col, Input, Button, message, Popconfirm, Select } from 'antd'
import { enterprise, axiosPost, axiosPut } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'
import styles from './dynamic.module.css'

import ImagesList from '../../components/imagesList'

import noAvatar from '../../assets/images/header_no_avatar_circle.png';
import dynamicGood from '../../assets/images/ic_circle_dynamic_good.png'
import dynamicUnGood from '../../assets/images/ic_circle_dynamic_un_good.png'
import dynamicComment from '../../assets/images/ic_circle_dynamic_comment.png'
import dynamicImgDel from '../../assets/images/ic_circle_dynamic_imgDelete.png'
import goTopImg from '../../assets/images/goTop.png'

const { TextArea } = Input
const { Option } = Select

const showType = [
    {
        name: '仅自己可见',
        key: '0'
    },
    {
        name: '关注我的',
        key: '1'
    },
    {
        name: '圈子可见',
        key: '2'
    },
    {
        name: '所有人',
        key: '3'
    },
]

let isReq = true

export default class Dynamic extends Component {
    constructor() {
        super()
        this.newDynamicImgRef = React.createRef()
        this.state = {
            user: {},
            dynamicIndex: 0,
            dynamicSize: 10,
            dynamics: [],
            readyCommentDynamic: '',
            readyCommentDynamicIndex: - 1,
            commentContent: '',
            newDynamicContent: '',
            newDynamicImgs: [],
            actShowType: '3',
            hasCircles: [],
            hasChoosedCircle: [],
            moreOver: '0',
            moreToast: '加载更多数据',
            showBigImg: false,
            sources: [],
            actSource: '',
            toTopBtnShow: false
        }
    }

    componentDidMount() {
        this.props.onRef(this)
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                }, () => this.getUserCircle())
                this.getDynamic()
            }
        })
        window.addEventListener('click', this.documentClick)
        // window.addEventListener('scroll', this.scrollY)
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.documentClick);
        this.setState = (state, callback) => {
            return
        }
    }

    // 监听页面点击事件，当点击非评论输入框地方时，隐藏输入框和清空输入内容
    documentClick = () => {
        this.setState({
            readyCommentDynamic: '',
            commentContent: '',
            readyCommentDynamicIndex: -1
        })
    }

    // 监听滚动条距离
    scrollY = () => {
        const { moreOver, dynamics } = this.state
        if (moreOver === '0' && dynamics.length < 60) {
            if (isReq) {
                isReq = false
                this.moreTimeout()
                setTimeout(function () {
                    isReq = true;
                }, 1000);
            }
        }
    }

    scrolltoTop=()=>{
        this.props.goTop()
    }

    // 返回顶部按钮
    goTopButton = (flag) => {
        if (flag === 'true' && !this.state.toTopBtnShow) {
            this.setState({
                toTopBtnShow: true
            })
        } else if (flag === 'false' && this.state.toTopBtnShow) {
            this.setState({
                toTopBtnShow: false
            })
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                }, () => this.getUserCircle())
                this.getDynamic()
            }
        })
    }

    // 获取用户已加入的圈子
    getUserCircle = () => {
        const { user } = this.state
        if (user.type === '-1') {
            return
        }
        const params = {
            enterprise: enterprise,
            type: '1'
        }
        let token = getCookieValue('current-user').token
        axiosPost(21, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    hasCircles: res.data.list
                })
            } else {
                this.setState({
                    hasCircles: []
                })
            }
        })
    }

    // 获取圈子动态
    getDynamic = () => {
        const { dynamicIndex, dynamicSize, dynamics } = this.state
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            key: '',
            person: '',
            index: dynamicIndex,
            size: dynamicSize
        }
        this.setState({
            moreToast: '正在加载中...'
        })
        axiosPost(24, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    dynamics: [...dynamics, ...res.data.list],
                    moreToast: '加载更多数据'
                })
            } else {
                this.setState({
                    moreOver: '1',
                    moreToast: '没有更多数据'
                })
            }
        })
    }

    // 加载更多动态数据
    getMoreDynamics = () => {
        const { moreOver } = this.state
        if (moreOver === '0') {
            this.setState({
                dynamicIndex: this.state.dynamicIndex + 1
            }, () => this.getDynamic())
        }
    }

    moreTimeout = () => {
        this.setState({
            dynamicIndex: this.state.dynamicIndex + 1
        }, () => this.getDynamic())
    }

    // 动态输入框  输入内容改变
    dynamicInputChange = (e) => {
        this.setState({
            newDynamicContent: e.target.value
        })
    }

    // 点击选择图片按钮
    chooseImg = () => {
        this.newDynamicImgRef.current.click()
    }

    // 选择图片完成，添加到图片列表中
    newDynamicImgChange = (e) => {
        const { newDynamicImgs } = this.state
        if (e.target.files.length > 0) {
            if (newDynamicImgs.length + e.target.files.length <= 12) {
                this.setState({
                    newDynamicImgs: [...this.state.newDynamicImgs, ...e.target.files]
                })
            } else {
                message.error('图片数量过多，请筛选一部分')
            }
        }
    }

    // 动态可见人类型改变
    showTypeChange = (e) => {
        this.setState({
            actShowType: e
        })
    }

    // 可见类型为圈子时  选在可见的圈子
    canseeCircleChange = (e) => {
        this.setState({
            hasChoosedCircle: e
        })
    }

    // 删除已选图片
    deleteDynamicImg = (index) => {
        const { newDynamicImgs } = this.state
        let copyDynamicImgs = [...newDynamicImgs]
        copyDynamicImgs.splice(index, 1)
        this.setState({
            newDynamicImgs: copyDynamicImgs
        })
    }

    // 点击发布
    goRelease = () => {
        const { newDynamicImgs, newDynamicContent, actShowType, hasChoosedCircle } = this.state
        if (newDynamicContent.trim() === '') {
            message.error('动态内容不能为空')
            return
        }
        let circle
        if (actShowType === '2') {
            if (hasChoosedCircle.length > 0) {
                circle = hasChoosedCircle.toString()
            } else {
                message.error('请选择可见的圈子')
                return
            }
        } else {
            circle = ''
        }
        let type = newDynamicImgs.length > 0 ? '1' : '0'
        const params = {
            enterprise: enterprise,
            type: type,
            content: newDynamicContent,
            showType: actShowType,
            circle: circle
        }
        let token = getCookieValue('current-user').token
        axiosPut(0, token, params, newDynamicImgs, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    dynamics: [],
                    dynamicIndex: 0,
                    newDynamicContent: '',
                    newDynamicImgs: [],
                    actShowType: '3',
                    hasChoosedCircle: []
                }, () => this.getDynamic())
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }

    // 删除自己发的动态
    deleteDynamic = (id, index) => {
        const { dynamics } = this.state
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            id: id
        }
        axiosPost(25, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                let newDynamics = dynamics
                newDynamics.splice(index, 1)
                this.setState({
                    dynamics: newDynamics
                })
            }
        })
    }

    // 关注 或 取消关注发布动态的人
    followUser = (id, flag) => {
        const { dynamics } = this.state
        const params = {
            enterprise: enterprise,
            teacher: id
        }
        let token = getCookieValue('current-user').token
        let action
        if (flag === '0') {
            action = 10
        } else {
            action = 11
        }
        axiosPost(action, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                let newDynamics = []
                dynamics.forEach(item => {
                    let newItem = item
                    if (newItem.user.key === id) {
                        newItem.isFollow = flag === '0' ? '1' : '0'
                        newDynamics.push(newItem)
                    } else {
                        newDynamics.push(item)
                    }
                })
                this.setState({
                    dynamics: newDynamics
                })
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }

    // 点赞操作
    setGood = (item, index) => {
        const { dynamics, user } = this.state
        if (user.type === '-1') {
            this.props.goLogin()
            return
        }
        if (item.isGood === '0') {
            //  尚未点赞  执行点赞
            let token = getCookieValue('current-user').token
            const params = {
                enterprise: enterprise,
                id: item.id,
                type: '0',
                content: ''
            }
            axiosPost(26, token, params, 'ArtVip', (res) => {
                if (res.code === 0) {
                    let newDynamics = dynamics
                    // newDynamics[index].isGood = '1'
                    newDynamics[index].goodCount = parseInt(dynamics[index].goodCount) + 1
                    this.setState({
                        dynamics: newDynamics
                    })
                } else {
                    message.destroy()
                    message.error(res.message)
                }
            })
        } else {
            // 已点赞  取消点赞
            let token = getCookieValue('current-user').token
            const params = {
                enterprise: enterprise,
                id: item.id,
                type: '0'
            }
            axiosPost(27, token, params, 'ArtVip', (res) => {
                if (res.code === 0) {
                    let newDynamics = dynamics
                    // newDynamics[index].isGood = '0'
                    newDynamics[index].goodCount = parseInt(dynamics[index].goodCount) - 1
                    this.setState({
                        dynamics: newDynamics
                    })
                } else {
                    message.destroy()
                    message.error(res.message)
                }
            })
        }
    }

    // 显示评论输入框
    goComment = (e, id, index) => {
        e.stopPropagation()
        this.setState({
            readyCommentDynamic: id,
            commentContent: '',
            readyCommentDynamicIndex: index
        }, () => {
            let commentInput = document.getElementById('commentInput')
            commentInput.focus()
        })
    }

    // 点击评论输入DIV时，阻止冒泡，防止监听的点击事件隐藏输入框
    commentDivClick = (e) => {
        e.stopPropagation()
    }

    // 评论内容变化
    commentChange = (e) => {
        this.setState({
            commentContent: e.target.value
        })
    }

    // 发布动态评论
    releaseComment = () => {
        const { commentContent, readyCommentDynamic, readyCommentDynamicIndex, dynamics, user } = this.state
        if (user.type === '-1') {
            this.props.goLogin()
            return
        }
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            id: readyCommentDynamic,
            type: '1',
            content: commentContent
        }
        axiosPost(26, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                let newDynamics = dynamics
                newDynamics[readyCommentDynamicIndex].comments.unshift(res.data.comment)

                this.setState({
                    commentContent: '',
                    readyCommentDynamic: '',
                    readyCommentDynamicIndex: - 1,
                    dynamics: newDynamics
                })
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }
    // 删除评论  仅自己的可以删除
    deleteComment = (id, index, dynamicId) => {
        const { dynamics } = this.state
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            id: id,
            type: '1'
        }
        axiosPost(27, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                let dynamicsKeys = []
                dynamics.forEach(item => dynamicsKeys.push(item.id))
                let dynamicIndex = dynamicsKeys.indexOf(dynamicId)
                let newDynamics = dynamics
                newDynamics[dynamicIndex].comments.splice(index, 1)
                this.setState({
                    dynamics: newDynamics
                })
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }

    showBigImg = (sources, actSource) => {
        this.setState({
            sources: sources,
            actSource: actSource,
            showBigImg: true
        })
    }

    hideBigImg = () => {
        this.setState({
            sources: [],
            actSource: '',
            showBigImg: false
        })
    }

    // 结构函数
    // 每条评论的结构
    getDynamicDiv = (item, index) => {
        const { readyCommentDynamic, commentContent, user } = this.state
        return <div className={styles.eachDynamicBox}>
            <div className={styles.personBox}>
                <div className={styles.personAvatarBox}>
                    <img src={item.user.image} onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} alt='' />
                </div>
                <div className={styles.personName}>
                    <h2>{item.user.name}</h2>
                    <span>{item.createdOn}</span>
                </div>
                {
                    item.user.key === user.key ? <Popconfirm placement="top" title='确定删除吗？' onConfirm={() => this.deleteDynamic(item.id, index)} okText="确定" cancelText="取消">
                        <span className={styles.deleteDynamicTag}>删除</span>
                    </Popconfirm> :
                        item.isFollow === '1' ? <Popconfirm placement="top" title='确定取消关注吗？' onConfirm={() => this.followUser(item.user.key, '1')} okText="确定" cancelText="取消">
                            <span className={styles.followUserTag}>已关注</span>
                        </Popconfirm> :
                            <Popconfirm placement="top" title='确定关注吗？' onConfirm={() => this.followUser(item.user.key, '0')} okText="确定" cancelText="取消">
                                <span className={styles.unFollowUserTag}>关注</span>
                            </Popconfirm>
                }
            </div>
            <div className={styles.contentBox}>
                <p>{item.content}</p>
                <div className={styles.contentImgBox}>
                    <Row gutter={[10, 10]}>
                        {item.sources.map(source => <Col span={6} key={source}>
                            <div className={styles.eachImgBox} onClick={() => this.showBigImg(item.sources, source)}>
                                <img src={source} alt='' />
                            </div>
                        </Col>)}
                    </Row>
                </div>
            </div>
            <div className={styles.actionBox}>
                <span ><img src={item.isGood === '0' ? dynamicUnGood : dynamicGood} alt='' onClick={() => this.setGood(item, index)} />{item.goodCount}</span>
                <span onClick={(e) => this.goComment(e, item.id, index)}><img src={dynamicComment} alt='' /></span>
            </div>
            <div className={styles.commentBox}>
                {item.comments.map((comment, commentIndex) => <div key={comment.id} style={{ padding: '10px 20px 5px 20px' }}>
                    <Row wrap={false} gutter={[10, 0]}>
                        <Col flex='none'>
                            <div className={styles.commentPersonAvatar}>
                                <img src={comment.user.image} onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} alt='' />
                            </div>
                        </Col>
                        <Col flex='auto'>
                            <div className={styles.commentContentBox}>
                                <p><span className={styles.commentPersonName}>{comment.user.name}</span>&nbsp;: &nbsp;
                                    <span className={styles.commentContent}>{comment.content}</span>
                                    <Popconfirm placement="top" title='确定删除吗？' onConfirm={() => this.deleteComment(comment.id, commentIndex, item.id)} okText="确定" cancelText="取消">
                                        <span className={styles.commentDel} style={{ display: comment.user.key === user.key ? 'inline-block' : 'none' }}>删除</span>
                                    </Popconfirm>
                                </p>
                                <span className={styles.commentTime}>{comment.time}</span>
                            </div>
                        </Col>
                    </Row>
                </div>)}
            </div>
            <div className={styles.writeCommentBox}>
                {
                    readyCommentDynamic === item.id ? <div id='commentDiv' onClick={(e) => this.commentDivClick(e)} className={styles.writeBoxSelect}>
                        <TextArea id='commentInput' onChange={this.commentChange} value={commentContent}></TextArea>
                        <Button type='primary' style={{ marginTop: '5px' }} onClick={this.releaseComment}>发布</Button>
                    </div> : <div onClick={(e) => this.goComment(e, item.id, index)} className={styles.writeBoxUnselect}>
                        评论
                        </div>
                }
            </div>
        </div>
    }

    // 新增动态时  添加的图片盒子
    getNewDynamicImgDiv = (item, index) => {
        let itemUrl = URL.createObjectURL(item)
        return <div className={styles.eachNewDynamicImgBox}>
            <img src={itemUrl} alt='' className={styles.eachNewDynamicImg} />
            <Popconfirm placement="top" title='确定删除吗？' onConfirm={() => this.deleteDynamicImg(index)} okText="确定" cancelText="取消">
                <img src={dynamicImgDel} alt='' className={styles.deleteImg} />
            </Popconfirm>
        </div>
    }

    render() {
        const { user, dynamics, newDynamicContent, newDynamicImgs, actShowType,toTopBtnShow, hasCircles, hasChoosedCircle, moreToast, showBigImg, actSource, sources } = this.state
        return (
            <div>
                <div style={{ display: 'none' }}>
                    <input type='file' accept='*/image' ref={this.newDynamicImgRef} onChange={this.newDynamicImgChange} multiple='multiple' />
                </div>
                <div className={styles.releaseBox} style={{ display: user.type === '-1' ? 'none' : 'block' }}>
                    <div>
                        <TextArea rows={3} value={newDynamicContent} onChange={this.dynamicInputChange} />
                        <div style={{ margin: '10px 0' }}>
                            <Button type='primary' onClick={this.goRelease}>发布</Button>
                            <Select style={{ width: '150px', margin: '0 10px' }} onChange={this.showTypeChange} value={actShowType}>
                                {
                                    showType.map(item => <Option key={item.key} value={item.key}>
                                        {item.name}
                                    </Option>)
                                }
                            </Select>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '300px', display: actShowType === '2' ? 'inline-block' : 'none' }}
                                placeholder='请选择可见的圈子'
                                onChange={this.canseeCircleChange}
                                value={hasChoosedCircle}
                            >
                                {hasCircles.map(item => <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>)}
                            </Select>
                            <div style={{ float: 'right' }}>
                                <Button type='primary' onClick={this.chooseImg}>选择图片</Button>
                            </div>
                        </div>
                        <div className={styles.newDynamicImgsBox} style={{ display: newDynamicImgs.length > 0 ? 'block' : 'none' }}>
                            <h2 className={styles.newDynamicImgsNumber}>图片：{newDynamicImgs.length}/12</h2>
                            <Row gutter={[20, 20]}>
                                {newDynamicImgs.map((item, index) => <Col span={8} key={index}>
                                    {this.getNewDynamicImgDiv(item, index)}
                                </Col>)}
                            </Row>
                        </div>
                    </div>
                </div>
                <div className={styles.dynamicsBox}>
                    {dynamics.map((item, index) => <div key={item.id}>
                        {this.getDynamicDiv(item, index)}
                    </div>)}
                </div>
                <div className={styles.loadingBox} onClick={this.getMoreDynamics}>
                    {moreToast}
                </div>
                {
                    showBigImg ? <ImagesList hide={this.hideBigImg} actSource={actSource} sources={sources} /> : ''
                }
                <div className={styles.fixedButton} style={{ display: toTopBtnShow ? 'block' : 'none' }}>
                    <div title='返回顶部' onClick={this.scrolltoTop}>
                        <img src={goTopImg} alt='' />
                    </div>
                </div>
            </div>
        )
    }
}
