import React, { Component } from "react";
import { Row, Col, message } from 'antd'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { getCookieValue, setCookieValue } from '../../utils/cookieUtil'
import { checkUser } from '../../utils/currentUser'
import styles from './usercenter.module.css'

import Header from '../../components/header.js'

import UserHistory from './userData/userHistory'
import UserCollect from './userData/userCollect'
import UserFocus from './userData/userFocus'
import UserBuy from './userData/userBuy'
import Account from './userData/account'
import Coupon from './userData/coupon'
import GiftCard from './userData/giftCard'
import Points from './userData/points'
import Qa from './userData/qa'

import noAvatar from '../../assets/images/header_no_avatar_circle.png';
import headerIsBuy from '../../assets/images/ic_person_order.png'
import headerMyHistory from '../../assets/images/ic_person_history.png'
import headerMyCollect from '../../assets/images/ic_person_collect.png'
import headerMyFocus from '../../assets/images/ic_person_focus_on.png'


const comList = [
    {
        title: '播放记录',
        image: headerMyHistory,
        key: 'default'
    },
    {
        title: '我的收藏',
        imgage: headerMyCollect,
        key: 'myCollect'
    },
    {
        title: '我的关注',
        imgage: headerMyFocus,
        key: 'myFocus'
    },
    {
        title: '已购视频',
        imgage: headerIsBuy,
        key: 'myBuy'
    },
]
const jifenLis = [
    {
        title: '账户',
        key: 'account',
    },
    {
        title: '积分',
        key: 'points',
    },
    {
        title: '礼品卡',
        key: 'giftCard',
    },
    {
        title: '优惠卷',
        key: 'coupon',
    },
]

export default class UserCenter extends Component {
    constructor() {
        super()
        this.state = {
            user: {},
            actKey: ''
        }
    }

    componentDidMount() {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                
                this.getPersonInfo()
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        this.setState({
            actKey: ''
        }, () => {
            let actKey = getCookieValue('centerFlag')
            this.setState({
                actKey: actKey
            })
        })
    }

    // 获取个人页基础信息
    getPersonInfo = () => {
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise
        }
        axiosPost(28, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                let actKey = getCookieValue('centerFlag')
                this.setState({
                    account: res.data.account,
                    coupon: res.data.coupon,
                    giftCard: res.data.giftCard,
                    points: res.data.points,
                    qa: res.data.qa,
                    actKey: actKey
                })
            }
        })
    }

    // 点击登录
    goLogin = () => {
        this.$Hearder.goLogin()
    }

    // 跳转用户中心
    goUsercenter = (flag) => {
        setCookieValue('centerFlag', flag)

        this.setState({
            actKey: flag
        })
    }

    dataLiClick = (key) => {
        setCookieValue('centerFlag', key)
        this.setState({
            actKey: key
        })
    }

    // 签到
    signIn = () => {
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise
        }
        axiosPost(31, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                message.destroy()
                message.success(res.message, 1)
            } else {
                message.destroy()
                message.error(res.message, 1)
            }
        })
    }

    render() {
        const { user, actKey, account, coupon, giftCard, points, qa } = this.state
        return (
            <div>
                <Header history={this.props.history} reData={this.reData} onRef={(ref) => { this.$Hearder = ref }} goUsercenter={this.goUsercenter} />
                <div className={styles.container}>
                    <div style={{ marginTop: '20px' }}>
                        <Row wrap={false} gutter={40}>
                            <Col flex='none'>
                                <div style={{ width: '200px' }}>
                                    <div className={styles.userInfo}>
                                        <img src={user.image} alt='' onClick={() => this.goUsercenter('default')} onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
                                        <h2>{user.name}</h2>
                                        <p>积分：0</p>
                                        <span onClick={this.signIn}>签到</span>
                                    </div>
                                    <div className={styles.dataListBox}>
                                        <ul>
                                            {
                                                comList.map(item => <li key={item.key} onClick={() => { this.dataLiClick(item.key) }} style={{ borderRight: item.key === actKey ? '2px solid #f1281e' : 'none', backgroundColor: item.key === actKey ? '#f5dedc' : '#fff' }}>
                                                    {item.title}
                                                </li>)
                                            }
                                        </ul>
                                        <ul style={{ borderTop: '1px solid #eee' }}>
                                            {
                                                jifenLis.map(item => <li key={item.key} onClick={() => { this.dataLiClick(item.key) }} style={{ borderRight: item.key === actKey ? '2px solid #f1281e' : 'none', backgroundColor: item.key === actKey ? '#f5dedc' : '#fff' }}>
                                                    {item.title}
                                                </li>)
                                            }
                                        </ul>
                                        <ul style={{ borderTop: '1px solid #eee' }}>
                                            <li onClick={() => { this.dataLiClick('qa') }} style={{ borderRight: actKey === 'qa' ? '2px solid #f1281e' : 'none', backgroundColor: actKey === 'qa' ? '#f5dedc' : '#fff' }}>
                                                设置
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col flex='auto'>
                                <div>
                                    {
                                        actKey === 'default' ? <UserHistory goLogin={this.goLogin} history={this.props.history} /> :
                                            actKey === 'myCollect' ? <UserCollect goLogin={this.goLogin} history={this.props.history} /> :
                                                actKey === 'myFocus' ? <UserFocus goLogin={this.goLogin} history={this.props.history} /> :
                                                    actKey === 'myBuy' ? <UserBuy goLogin={this.goLogin} history={this.props.history} /> :
                                                        actKey === 'account' ? <Account account={account} goLogin={this.goLogin} history={this.props.history} /> :
                                                            actKey === 'coupon' ? <Coupon coupon={coupon} goLogin={this.goLogin} history={this.props.history} /> :
                                                                actKey === 'giftCard' ? <GiftCard giftCard={giftCard} goLogin={this.goLogin} history={this.props.history} /> :
                                                                    actKey === 'points' ? <Points points={points} goLogin={this.goLogin} history={this.props.history} /> :
                                                                        actKey === 'qa' ? <Qa qa={qa} goLogin={this.goLogin} history={this.props.history} /> : ''
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}