import { getCookieValue } from './cookieUtil'
import { enterprise, axiosPost } from './requestUtil'
export function getVideos(require, callback) {
    const params = {
        enterprise: enterprise,
        channel: require.channel ? require.channel : '',
        topic: require.topic ? require.topic : '',
        id: require.id ? require.id : '',
        recommend: require.recommend ? require.recommend : '',
        hot: require.hot ? require.hot : '',
        keyword: require.keyword ? require.keyword : '',
        teacher: require.teacher ? require.teacher : '',
        sort: require.sort ? require.sort : '',
        sortBy: require.sortBy ? require.sortBy : '',
        type: require.type ? require.type : '',
        index: require.index ? require.index : 0,
        size: require.size ? require.size : 20,
    }
    let token = getCookieValue('current-user').token
    axiosPost(6, token, params, 'ArtVip', (res) => {
        callback(res)
    })
}

export function lengthToTime(str) {
    let timeNum = Math.round(JSON.parse(str))
    let hh = parseInt(timeNum / 3600)
    let mm = parseInt(timeNum / 60)
    let ss = parseInt(timeNum % 60)
    let timeStr = ''
    let m = ''
    let s = ''
    if (mm < 10) {
        m = '0' + mm
    } else {
        m = mm + ''
    }
    if (ss < 10) {
        s = '0' + ss
    } else {
        s = ss + ''
    }
    if (hh === 0) {
        timeStr = m + ':' + s
    } else {
        timeStr = hh + ':' + m + ':' + s
    }
    return timeStr
}

export function timeformdate(str) {
    let strArr = str.split('T')
    let newTime = strArr[1].split('.')
    let newStr = strArr[0] + ' ' + newTime[0]
    return newStr
}
