export function getCookieValue(name){
    try{
        if(localStorage.getItem(name)!==null&&localStorage.getItem(name)!==undefined&&localStorage.getItem(name)!=='undefined'){
            return JSON.parse(localStorage.getItem(name));
        }
        return null;
    }
    catch(e)
    {
        console.log(e);
        return null;
    }
}

export function setCookieValue(name,value){
    try{
        localStorage.removeItem(name);
    }catch(e){
        console.log(e);
    }
    var str = JSON.stringify(value);
    localStorage.setItem(name, str);
}

export function removeCookieValue(name){
    try{
        localStorage.removeItem(name);
    }catch(e){
        console.log(e);
    }
}