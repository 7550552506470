import { v4 as uuidv4 } from 'uuid'
import { axiosPost, version } from './requestUtil'
import { setCookieValue, getCookieValue } from "./cookieUtil";

export function setDefault(id, callback) {
    let newId
    if (id) {
        newId = id
    } else {
        const date = new Date()
        var newid = uuidv4()
        var time = date.getTime().toString().substring(0, 10)
        newId = time + newid
    }

    const params = {
        id: newId,
        brand: 'PC',
        model: 'PC',
        version: version,
        ip: ''
    }
    axiosPost(0, '', params, 'ArtVip', (res) => {
        if (res.code === 0) {
            setCookieValue('default-id', newId)
            setCookieValue('current-user', res.data.users[0])
        }
        return callback(res)
    })
}

export function checkUser(callback) {
    let currentUser = getCookieValue('current-user')
    if (!currentUser) {
        let defauleId = getCookieValue('default-id')
        setDefault(defauleId, res => {
            if (res.code === 0) {
                let newRes = {
                    code: 0,
                    user: res.data.users[0]
                }
                return callback(newRes)
            }
        })
    } else {
        let newRes = {
            code: 0,
            user: currentUser
        }
        return callback(newRes)
    }
}