import React, { Component } from "react";
import { Breadcrumb, Row, Col, Pagination, Popconfirm, Empty } from "antd";
import { Link } from 'react-router-dom'
import { enterprise, axiosPost } from '../../utils/requestUtil';
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'
import { getVideos } from "../../utils/getVideoUtil";
import styles from './videoLists.module.css'

import Header from '../../components/header.js'
import Foot from '../../components/footer.js';

import noAvatar from '../../assets/images/header_no_avatar_circle.png';
import playArt from '../../assets/images/art_play.png'

const serchSort = [
    {
        key: 'createdOn',
        title: '最新上传',
    },
    {
        key: 'viewCount',
        title: '播放最多',
    },
    {
        key: 'commentCount',
        title: '评论最多',
    },
    {
        key: 'goodCount',
        title: '点赞最多',
    },
]

export default class VideoLists extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contentHeight: 0,
            user: {},
            videoLists: [],
            videoListCount: 0,
            allChannels: [],
            allTeachers: [],
            allTopics: [],
            requires: {
                channel: '',
                topic: '',
                id: '',
                recommend: '',
                hot: '',
                keyword: '',
                teacher: '',
                sort: '',
                sortBy: '',
                type: '',
                index: 0
            },
        }
    }

    componentDidMount() {
        const contentHeight = window.innerHeight
        this.setState({
            contentHeight,
        })
        // window.addEventListener('resize', this.resizeBind)
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getAllChannels()
                this.getAllTopics()
                this.getAllTeachers()
                this.getSessionReq()
            }
        })
    }

    // resizeBind=()=>{
    //     this.setState({
    //         contentHeight:document.documentElement.clientHeight
    //     })
    // }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeBind)
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getAllChannels()
                this.getAllTopics()
                this.getAllTeachers()
                this.getSessionReq()
            }
        })
    }


    getAllChannels = () => {
        const params = {
            enterprise: enterprise
        }
        let token = getCookieValue('current-user').token
        axiosPost(7, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    allChannels: res.data.list
                })
            }
        })
    }
    getAllTopics = () => {
        const params = {
            enterprise: enterprise
        }
        let token = getCookieValue('current-user').token
        axiosPost(8, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    allTopics: res.data.list
                })
            }
        })
    }
    getAllTeachers = () => {
        const params = {
            enterprise: enterprise,
            keyword: '',
            index: 0,
            size: 9999
        }
        let token = getCookieValue('current-user').token
        axiosPost(9, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    allTeachers: res.data.list
                })
            }
        })
    }

    getSessionReq = () => {
        const { requires } = this.state
        let searchKey = JSON.parse(sessionStorage.getItem('search-video'))
        if (searchKey) {
            let newRequires = {
                channel: searchKey.channel ? searchKey.channel : requires.channel,
                topic: searchKey.topic ? searchKey.topic : requires.topic,
                id: searchKey.id ? searchKey.id : requires.id,
                recommend: searchKey.recommend ? searchKey.recommend : requires.recommend,
                hot: searchKey.hot ? searchKey.hot : requires.hot,
                keyword: searchKey.keyword ? searchKey.keyword : requires.keyword,
                teacher: searchKey.teacher ? searchKey.teacher : requires.teacher,
                sort: searchKey.sort ? searchKey.sort : requires.sort,
                sortBy: searchKey.sortBy ? searchKey.sortBy : requires.sortBy,
                type: searchKey.type ? searchKey.type : requires.type,
                index: searchKey.index ? searchKey.index : requires.index,
            }
            this.setState({
                requires: newRequires
            }, () => {
                sessionStorage.removeItem('search-video')
                this.getVideoLists()
            })

        } else {
            this.getVideoLists()
        }
    }

    getVideoLists = () => {
        const { requires } = this.state

        getVideos(requires, (res) => {
            if (res.code === 0) {
                this.setState({
                    videoLists: res.data.list,
                    videoListCount: res.data.total
                })
                document.documentElement.scrollTop = 0

            } else {
                this.setState({
                    videoLists: [],
                    videoListCount: 0
                })
                document.documentElement.scrollTop = 0
            }

        })
    }

    // 头部搜索框 按enter或点击搜索按钮
    searchIpt = (e) => {
        let newRequires = {
            channel: '',
            topic: '',
            id: '',
            recommend: '',
            hot: '',
            keyword: e,
            teacher: '',
            sort: '',
            sortBy: '',
            type: '',
            index: 0
        }
        this.setState({
            requires: newRequires
        }, () => this.getVideoLists())
    }

    // 分类选中改变
    channelChange = (item) => {
        const { requires } = this.state
        let newRequires = requires
        newRequires.channel = item
        newRequires.index = 0
        this.setState({
            requires: newRequires
        }, () => this.getVideoLists())
    }

    // 排序类型改变
    sortByChange = (item) => {
        const { requires } = this.state
        let newRequires = requires
        newRequires.sort = item
        if (item === '') {
            newRequires.sortBy = ''
            newRequires.index = 0
        }
        newRequires.sortBy = 'desc'
        newRequires.index = 0
        this.setState({
            requires: newRequires
        }, () => this.getVideoLists())
    }

    // 是否推荐
    recommendChange = () => {
        const { requires } = this.state
        let newRequires = requires
        newRequires.recommend = requires.recommend === 1 ? '' : 1
        newRequires.index = 0
        this.setState({
            requires: newRequires
        }, () => this.getVideoLists())
    }

    // 是否热门
    hotChange = () => {
        const { requires } = this.state
        let newRequires = requires
        newRequires.hot = requires.hot === 1 ? '' : 1
        newRequires.index = 0
        this.setState({
            requires: newRequires
        }, () => this.getVideoLists())
    }

    // 翻页操作
    pageChange = (e) => {
        const { requires } = this.state
        let newRequires = requires
        newRequires.index = e - 1
        this.setState({
            requires: newRequires
        }, () => this.getVideoLists())
    }

    // 关注
    goConcerns = (item) => {
        const { user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        const params = {
            enterprise: enterprise,
            teacher: item.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(10, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.getAllTeachers()
            }
        })
    }

    // 取消关注 
    cancelConcerns = (item) => {
        const params = {
            enterprise: enterprise,
            teacher: item.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(11, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.getAllTeachers()
            }
        })
    }

    // 跳转到讲师列表
    goTeacherList = () => {
        this.props.history.push('/home/teacherList')
    }

    //跳转到讲师详情列表
    goTeacherDetail = (item) => {
        sessionStorage.setItem('teacherDetail', JSON.stringify(item))
        this.props.history.push('/home/teacherDetail')
    }

    // 结构生成函数
    // 视频盒子结构
    getVideoBox = (item) => {
        let tagString = item.tags
        let tags = tagString.split(',')
        return <div className={styles.eachVideoBox}>
            <Link target='_blank' to={{ pathname: `/home/videoplay/${item.id}` }}>
                <div className={styles.coverImgBox}>
                    <img
                        alt=""
                        src={item.pictrue}
                    />
                    <div className={styles.playIconBox}>
                        <img src={playArt} alt='' />
                    </div>
                </div>
            </Link>

            <div className={styles.videoIntro}>
                <h2>{item.name}</h2>
                <div className={styles.tagsBox}>
                    {tags.map((tag, index) => <span className={styles.tag} key={index}>{tag}</span>)}
                </div>
                <span>主讲人：{item.teacherName}</span>
            </div>
        </div>
    }
    // 讲师盒子结构
    teacherBox = (item) => {
        return <div className={styles.teacherLi}>
            <div className={styles.teacherImgbox} onClick={() => this.goTeacherDetail(item)}>
                <img src={item.image} alt='' onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
            </div>
            <h2>{item.name}</h2>
            {
                item.isFollow + '' === '0' ? <Popconfirm onConfirm={() => this.goConcerns(item)} title="确定关注吗？" okText="确认" cancelText="取消">
                    <span className={styles.noconcerns}>关注</span>
                </Popconfirm> : <Popconfirm onConfirm={() => this.cancelConcerns(item)} title="确定取消关注吗？" okText="确认" cancelText="取消">
                    <span className={styles.isconcerns}>已关注</span>
                </Popconfirm>
            }
        </div>
    }

    render() {
        const { allChannels, requires, videoLists, videoListCount, contentHeight, allTeachers } = this.state
        return (
            <div>
                <Header history={this.props.history} searchIpt={this.searchIpt} reData={this.reData} onRef={(ref) => { this.$Hearder = ref }} />
                <div className={styles.container}>
                    <div className={styles.breadcrumbBox}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Item href='/home'>首页</Breadcrumb.Item>
                            <Breadcrumb.Item>视频列表</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={styles.filterBox}>
                        <div className={styles.filterAreaBox}>
                            <span className={styles.filterAll} onClick={() => this.channelChange('')} style={{ backgroundColor: requires.channel === '' ? '#f1281e' : '#fff', color: requires.channel === '' ? '#fff' : '#000' }}>不限</span>
                            {allChannels.map(item => <span style={{ backgroundColor: requires.channel === item.id ? '#f1281e' : '#fff', color: requires.channel === item.id ? '#fff' : '#000' }} className={styles.filterLi} key={item.id} onClick={() => this.channelChange(item.id)} >{item.name}</span>)}
                        </div>
                        <div className={styles.filterAreaBox}>
                            <span className={styles.filterAll} onClick={() => this.sortByChange('')} style={{ backgroundColor: requires.sort === '' ? '#f1281e' : '#fff', color: requires.sort === '' ? '#fff' : '#000' }}>不限</span>
                            {serchSort.map(item => <span style={{ backgroundColor: requires.sort === item.key ? '#f1281e' : '#fff', color: requires.sort === item.key ? '#fff' : '#000' }} className={styles.filterLi} key={item.key} onClick={() => this.sortByChange(item.key)}>{item.title}</span>)}
                        </div>
                        <div className={styles.filterAreaBox}>
                            <span className={styles.recommendHotBox} style={{ backgroundColor: requires.recommend === 1 ? '#f1281e' : '#fff', color: requires.recommend === 1 ? '#fff' : '#000' }} onClick={this.recommendChange}>推荐视频</span>
                            <span className={styles.recommendHotBox} style={{ backgroundColor: requires.hot === 1 ? '#f1281e' : '#fff', color: requires.hot === 1 ? '#fff' : '#000' }} onClick={this.hotChange}>热门视频</span>
                        </div>
                    </div>
                    <div>
                        {videoListCount > 0 ? <Row gutter={[30, 30]}>
                            {videoLists.map(item => <Col span={6} key={item.id}>
                                {this.getVideoBox(item)}
                            </Col>)}
                        </Row> : <div>
                            <Empty />
                        </div>}

                    </div>
                    <div className={styles.paginationBox}>
                        <Pagination
                            total={videoListCount}
                            showTotal={total => `共 ${total} 条数据`}
                            current={requires.index + 1}
                            pageSize={20}
                            onChange={this.pageChange}
                            showSizeChanger={false}
                            size='default'
                            hideOnSinglePage
                        />
                    </div>
                </div>
                <div className={styles.teachersBgc}>
                    <div className={styles.teachersBox}>
                        <h2 className={styles.teachersBoxTitle}>名师推荐<span onClick={this.goTeacherList}>更多</span></h2>
                        <div style={{ overflow: 'hidden' }}>
                            <Row gutter={[20, 20]} wrap={false}>
                                {allTeachers.map(item => <Col span={3} key={item.id}>
                                    {this.teacherBox(item)}
                                </Col>)}
                            </Row>
                        </div>
                    </div>
                </div>
                <Foot />
            </div>
        )
    }
}