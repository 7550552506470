import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Input, message, Pagination, Breadcrumb, Empty, Modal } from 'antd'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'
import styles from './copybook.module.css'

import Header from '../../components/header.js'
import Foot from '../../components/footer.js';

const { Search } = Input
export default class Copybook extends Component {
    constructor() {
        super()
        this.state = {
            user: {},
            copybookCate: [],
            actCate: '',
            keyword: '',
            bookIndex: 0,
            bookSize: 24,
            copybookList: [],
            copybookTotal: 0,
            writerModalShow: false,
            actWriter: {}
        }
    }

    componentDidMount() {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getCopybookCate()
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getCopybookCate()
            }
        })
    }

    // 获取字帖分类数据
    getCopybookCate = () => {
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            size: ''
        }
        axiosPost(35, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    copybookCate: res.data.list,
                    actCate: res.data.list[0].id
                }, () => this.getCopybook())

            }
        })
    }

    // 获取字帖数据
    getCopybook = () => {
        const { actCate, keyword, bookIndex, bookSize } = this.state
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            type: actCate,
            keyword: keyword,
            id: '',
            index: bookIndex,
            size: bookSize
        }
        axiosPost(36, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    copybookList: res.data.list,
                    copybookTotal: res.data.total
                })
            } else {
                this.setState({
                    copybookList: [],
                    copybookTotal: 0
                })
            }
        })
    }

    // 输入关键字查询字帖
    searchBook = (e) => {
        if (e.trim() !== '') {
            this.setState({
                keyword: e,
                bookIndex: 0
            }, () => this.getCopybook())
        } else {
            message.destroy()
            message.error('搜索内容不能为空')
        }
    }

    // 翻页
    pageChange = (e) => {
        this.setState({
            bookIndex: e - 1
        }, () => this.getCopybook())
    }

    // 类别改变时获取类别数据
    actCateChange = (id) => {
        this.setState({
            actCate: id,
            bookIndex: 0
        }, () => this.getCopybook())
    }

    // 查看作者详情
    writerDetail = (writer) => {
        if (writer.id) {
            this.setState({
                writerModalShow: true,
                actWriter: writer
            })
        }
    }

    // 作者详情隐藏
    writerCancel = () => {
        this.setState({
            writerModalShow: false
        })
    }

    // 跳转到字帖详情图片
    gobookdetail = (id) => {
        const { user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        const w = window.open('about:blank')
        w.location.href = '/home/copybookDetail/' + id
    }

    // 结构生成函数
    // 字帖列表
    getBookDiv = (item) => {
        let tagString = item.tags
        let tags = tagString.split(',')
        return <div className={styles.bookBox}>
            {/* <Link target='_blank' to={{ pathname: `/home/copybookDetail/${item.id}` }}> */}
            <div className={styles.bookImgBox} onClick={() => this.gobookdetail(item.id)}>
                <img src={item.pageImage} alt='' />
            </div>
            <h2 title={item.name}>{item.name}</h2>
            {/* </Link> */}
            <p className={styles.bookWriter} onClick={() => this.writerDetail(item.writer)}>作者：<span>{item.writer.name}</span></p>
            <div className={styles.bookTagBox}>{tags.map(tag => <span key={tag}>{tag}</span>)}</div>
        </div>
    }

    render() {
        const { copybookCate, actCate, copybookList, copybookTotal, bookIndex, bookSize, writerModalShow, actWriter } = this.state
        return (
            <div>
                <Header history={this.props.history} reData={this.reData} onRef={(ref) => { this.$Hearder = ref }} />
                <div className={styles.container}>
                    <div className={styles.breadcrumbBox}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Item href='/home'>首页</Breadcrumb.Item>
                            <Breadcrumb.Item>字帖</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div style={{ padding: '15px 0' }}>
                        <Row>
                            <Col span={6}>
                                <Search placeholder='请输入关键字查询' style={{ width: '100%' }} onSearch={this.searchBook} enterButton />
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.bookCateBox}>
                        {copybookCate.map(item => <span key={item.id} style={{ color: actCate === item.id ? '#f1281e' : '#000' }} onClick={() => this.actCateChange(item.id)}>
                            {item.name}
                        </span>)}
                    </div>
                    <div>
                        {
                            copybookList.length > 0 ? <Row gutter={[20, 20]}>
                                {
                                    copybookList.map(item => <Col span={4} key={item.id}>
                                        {this.getBookDiv(item)}
                                    </Col>)
                                }
                            </Row> : <Empty />
                        }

                    </div>
                    <div className={styles.paginationBox}>
                        <Pagination
                            total={copybookTotal}
                            showTotal={total => `共 ${total} 条数据`}
                            current={bookIndex + 1}
                            pageSize={bookSize}
                            onChange={this.pageChange}
                            showSizeChanger={false}
                            size='default'
                            hideOnSinglePage
                        />
                    </div>
                </div>
                <Foot />
                <Modal
                    title='作者简介'
                    visible={writerModalShow}
                    onCancel={this.writerCancel}
                    footer={null}
                    destroyOnClose
                >
                    <div className={styles.writerDetailBox}>
                        <h2>
                            <img src={actWriter.image} alt='' />
                            <span>{actWriter.name}</span>
                        </h2>
                        <p>
                            {actWriter.desc}
                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}