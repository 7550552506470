import React, { Component } from 'react'
import { Row, Col, message, Empty } from 'antd'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'
import styles from './mine.module.css'

import addImg from '../../assets/images/ic_item_add_group.png'

export default class Mine extends Component {
    constructor() {
        super()
        this.state = {
            user: {},
            myCommunity: [],
            canjoinCommunity: []
        }
    }

    componentDidMount() {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                }, () => {
                    this.getMyCommunity()
                    this.getCanjoinCommunity()
                })
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                }, () => {
                    this.getMyCommunity()
                    this.getCanjoinCommunity()
                })
            }
        })
    }

    getMyCommunity = () => {
        const params = {
            enterprise: enterprise,
            type: '1'
        }
        let token = getCookieValue('current-user').token
        axiosPost(21, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    myCommunity: res.data.list
                })
            } else {
                this.setState({
                    myCommunity: []
                })
            }
        })
    }

    getCanjoinCommunity = () => {
        const params = {
            enterprise: enterprise,
            type: '0'
        }
        let token = getCookieValue('current-user').token
        axiosPost(21, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    canjoinCommunity: res.data.list
                })
            } else {
                this.setState({
                    canjoinCommunity: []
                })
            }
        })
    }

    // 加入社区
    joinCircle = (id, index) => {
        const { myCommunity, canjoinCommunity } = this.state
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            circle: id
        }
        axiosPost(22, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                let newMy = myCommunity
                let newCan = canjoinCommunity
                newCan[index].userCount = (parseInt(newCan[index].userCount) + 1).toString() 
                newMy.push(canjoinCommunity[index])
                newCan.splice(index, 1)
                this.setState({
                    myCommunity: newMy,
                    canjoinCommunity: newCan
                })
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }


    render() {
        const { myCommunity, canjoinCommunity } = this.state
        return (
            <div className={styles.mineBody}>
                <div>
                    <h2>我的社区</h2>
                    <div className={styles.myCircleBox}>
                        <ul>
                            {myCommunity.length > 0 ? myCommunity.map(item => <li key={item.id}>
                                <div className={styles.circleImage}>
                                    <img src={item.image} alt='' />
                                </div>
                                <div className={styles.circleInfoBox}>
                                    <h2>{item.name}</h2>
                                    <p>{item.userCount}人已关注</p>
                                    <span>{item.articleCount}条动态</span>
                                </div>
                                {/* <div className={styles.joinCircleImage}>
                                    <img src={addImg} alt='' onClick={this.joinCircle} />
                                </div> */}
                            </li>) : <Empty />}
                        </ul>
                    </div>
                    <h2>可以加入的社区</h2>
                    <div className={styles.myCircleBox}>
                        <ul>
                            {canjoinCommunity.length > 0 ? canjoinCommunity.map((item, index) => <li key={item.id}>
                                <div className={styles.circleImage}>
                                    <img src={item.image} alt='' />
                                </div>
                                <div className={styles.circleInfoBox}>
                                    <h2>{item.name}</h2>
                                    <p>{item.userCount}人已关注</p>
                                    <span>{item.articleCount}条动态</span>
                                </div>
                                <div className={styles.joinCircleImage}>
                                    <img src={addImg} alt='' onClick={() => this.joinCircle(item.id, index)} />
                                </div>
                            </li>) : <Empty />}
                        </ul>
                    </div>
                </div>
            </div >
        )
    }
}
