import React, { Component } from 'react'
import { Breadcrumb, Row, Col } from 'antd'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'
import styles from './articleList.module.css'

import Header from '../../components/header.js'

import goTopImg from '../../assets/images/goTop.png'
import viewCountImg from '../../assets/images/ic_article_view_count.png'
import goodCountImg from '../../assets/images/ic_article_good_count.png'
import commentCountImg from '../../assets/images/ic_article_comment_count.png'

let setTimeAjaxFlag = '0'

export default class ArticleList extends Component {
    constructor() {
        super()
        this.state = {
            contentHeight: 0,
            articleCate: [],
            actArticleCateId: '',
            articles: [],
            articleIndex: 0,
            articleSize: 10,
            notice: '加载更多数据',
            artLoadingOver: '0',
            toTopBtnShow: false,
        }
    }

    componentDidMount() {
        const contentHeight = window.innerHeight
        this.setState({
            contentHeight: contentHeight
        })


        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                let articleCate = JSON.parse(sessionStorage.getItem('articleCate'))
                if (articleCate) {
                    this.setState({
                        articleCate: articleCate,
                        actArticleCateId: articleCate[0].id
                    }, () => this.getArticles())
                } else {
                    this.getArticleCate()
                }
            }
        })
        window.addEventListener('scroll', this.onScroll)
    }

    onScroll = () => {
        if (document.documentElement.scrollTop > 200 && !this.state.toTopBtnShow) {
            this.setState({
                toTopBtnShow: true
            })
        } else if (document.documentElement.scrollTop <= 200 && this.state.toTopBtnShow) {
            this.setState({
                toTopBtnShow: false
            })
        }
        if (document.documentElement.scrollTop > document.body.scrollHeight - document.documentElement.clientHeight - 80) {
            if (setTimeAjaxFlag === '1') {
                return
            }
            setTimeout(() => {
                setTimeAjaxFlag = '0'
            }, 500)
            setTimeAjaxFlag = '1'
            if (this.state.articles.length < 30 && setTimeAjaxFlag === '1') {
                this.moreData()
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
        this.setState = (state, callback) => {
            return
        }
    }

    // 滚动到顶部
    scrolltoTop = () => {
        let top = document.documentElement.scrollTop
        let backStep = top / 50
        var scrollTopTimer = setInterval(function () {
            if (top > 0) {
                document.documentElement.scrollTop = top - backStep
                top = top - backStep
            } else {
                clearInterval(scrollTopTimer);
            }
        }, 10)
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                let articleCate = JSON.parse(sessionStorage.getItem('articleCate'))
                if (articleCate) {
                    this.setState({
                        articleCate: articleCate,
                        actArticleCateId: articleCate[0].id
                    }, () => this.getArticles())
                } else {
                    this.getArticleCate()
                }
            }
        })
    }

    // 获取文章分类
    getArticleCate = () => {
        const params = {
            enterprise: enterprise
        }
        let token = getCookieValue('current-user').token
        axiosPost(42, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    articleCate: res.data.list,
                    actArticleCateId: res.data.list[0].id
                }, () => this.getArticles())
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }

    // 获取文章列表
    getArticles = () => {
        const { actArticleCateId, articleIndex, articleSize, articles } = this.state
        const params = {
            enterprise: enterprise,
            channel: actArticleCateId,
            index: articleIndex,
            size: articleSize
        }
        let token = getCookieValue('current-user').token
        this.setState({
            notice: '数据加载中...',
            artLoadingOver: '0'
        })
        axiosPost(43, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    articles: [...articles, ...res.data.list],
                    notice: '加载更多数据'
                })
            } else if (res.code === 1) {
                this.setState({
                    notice: '暂无更多数据',
                    artLoadingOver: '1'
                })
            } else {
                message.destroy()
                message.error(res.message)
            }
        })
    }

    // 改变文章类别  获取新的数据
    artCateChange = (id) => {
        this.setState({
            actArticleCateId: id,
            articleIndex: 0,
            articles: []
        }, () => this.getArticles())
    }

    // 加载更多数据
    moreData = () => {
        const { artLoadingOver } = this.state
        if (artLoadingOver === '1') {
            return
        }
        this.setState({
            articleIndex: this.state.articleIndex + 1
        }, () => this.getArticles())
    }

    // 结构函数
    // 文章列表列
    getArticleLiBox = (item) => {
        return <Row gutter={[20, 0]} wrap={false}>
            <Col flex='none'>
                <div className={styles.liCoverBox}>
                    <img src={item.image} alt='' />
                </div>
            </Col>
            <Col flex='auto'>
                <div className={styles.liIntroBox}>
                    <a href={item.url} target='_blank' rel='noreferrer'>
                        <h2><span>{item.title}</span></h2>
                    </a>
                    <p>作者：{item.writer}</p>
                    <p className={styles.liCountBox}>
                        <span><img src={viewCountImg} alt='' />{item.viewCount}</span>
                        <span><img src={goodCountImg} alt='' />{item.commentCount}</span>
                        <span><img src={commentCountImg} alt='' />{item.goodCount}</span>
                    </p>
                </div>
            </Col>
        </Row>
    }


    render() {
        const { articleCate, articles, actArticleCateId, notice, toTopBtnShow } = this.state
        return (
            <div>
                <Header history={this.props.history} reData={this.reData} />
                <div className={styles.container}>
                    <div className={styles.breadcrumbBox}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Item href='/home'>首页</Breadcrumb.Item>
                            <Breadcrumb.Item>文章</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={styles.articalCateBox}>
                        <ul>
                            {articleCate.map(item => <li key={item.id} onClick={() => this.artCateChange(item.id)}>
                                {item.name}
                                <div className={styles.artCateBottomline} style={{ display: actArticleCateId === item.id ? 'block' : 'none' }}></div>
                            </li>)}
                        </ul>
                    </div>
                    <div className={styles.articleListBox}>
                        <ul>
                            {articles.length > 0 ? articles.map(item => <li key={item.id}>
                                {this.getArticleLiBox(item)}
                            </li>) : ''}
                        </ul>
                    </div>
                    <div onClick={this.moreData} className={styles.getMoreButton}>{notice}</div>
                </div>
                <div className={styles.fixedButton} style={{ display: toTopBtnShow ? 'block' : 'none' }}>
                    <div title='返回顶部' onClick={this.scrolltoTop}>
                        <img src={goTopImg} alt='' />
                    </div>
                </div>
            </div>
        )
    }
}