import React, { Component } from 'react'
import { Popconfirm } from 'antd'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'

import styles from './community.module.css'

import friend from '../../assets/images/ic_finding_function_friend.png'
import good from '../../assets/images/ic_finding_function_good.png'
import notice from '../../assets/images/ic_finding_function_notice.png'
import message from '../../assets/images/ic_finding_function_message.png'

const findings = [
    {
        img: message,
        title: '私信'
    },
    {
        img: good,
        title: '收到的赞'
    },
    {
        img: friend,
        title: '好友请求'
    },
    {
        img: notice,
        title: '系统消息'
    },
]

export default class Community extends Component {
    constructor() {
        super()
        this.state = {
            communities: []
        }
    }

    componentDidMount() {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                }, () => {
                    this.getCommunity()
                })
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getDynamic()
            }
        })
    }

    // 获取所有社区信息
    getCommunity = () => {
        const params = {
            enterprise: enterprise,
            type: ''
        }
        let token = getCookieValue('current-user').token
        axiosPost(21, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    communities: res.data.list
                })
            }
        })
    }

    // 加入社区操作
    joinCommunity = (id, index, flag) => {
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            circle: id
        }
        let action
        if (flag === '0') {
            action = 22
        }
        if (flag === '1') {
            action = 23
        }
        axiosPost(action, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                if (flag === '0') {
                    let newCommunities = this.state.communities
                    newCommunities[index].isJoin = '1'
                    this.setState({
                        communities: newCommunities
                    })
                }
                if (flag === '1') {
                    let newCommunities = this.state.communities
                    newCommunities[index].isJoin = '0'
                    this.setState({
                        communities: newCommunities
                    })
                }
            }
        })
    }


    render() {
        const { communities } = this.state
        return (
            <div className={styles.communityBody}>
                <div>
                    <h2>我的信息</h2>
                    <div>
                        {findings.map(item => <div key={item.title} className={styles.findingBox}>
                            <div><img src={item.img} alt='' /></div>
                            <span>{item.title}</span>
                        </div>)}
                    </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <h2>社区</h2>
                    {communities.map((item, index) => <div key={item.id} className={styles.communityBox}
                        style={{ backgroundColor: index % 4 === 0 ? '#ff3430' : index % 4 === 1 ? '#278dff' : index % 4 === 2 ? '#7339ff' : '#ff8624' }}>
                        <span className={styles.followCount}>{item.userCount}人已关注</span>
                        <img className={styles.communityImgBox} src={item.image} alt='' />
                        <h2 className={styles.communityNameBox}>{item.name}</h2>
                        <span className={styles.communityArticle}>{item.articleCount}条动态</span>
                        {item.isJoin === '1' ? <Popconfirm placement="top" title='确定退出吗？' onConfirm={() => this.joinCommunity(item.id, index, '1')} okText="确定" cancelText="取消">
                            <span className={styles.outJoin}>退出圈子</span>
                        </Popconfirm> : <Popconfirm placement="top" title='确定加入吗？' onConfirm={() => this.joinCommunity(item.id, index, '0')} okText="确定" cancelText="取消">
                            <span className={styles.toJoin}>立即加入</span>
                        </Popconfirm>}
                    </div>)}
                </div>
            </div >
        )
    }
}
