import React, { Component } from 'react';
import { Button } from 'antd'
import { checkUser } from '../../../utils/currentUser'

import pleaseLogin from '../../../assets/images/user_center_pleaseLogin.png';

export default class GiftCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
            giftCard: this.props.giftCard
        }
    }

    componentDidMount() {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    render() {
        const { giftCard, user } = this.state
        return (
            <div>
                {user.type && user.type !== '-1' ? <h2>当前礼品卡：{giftCard}</h2> : <div style={{ textAlign: 'center' }}>
                    <img src={pleaseLogin} alt='' style={{ height: '150px' }} />
                    <p style={{ color: '#ccc' }}>请登录后查看</p>
                    <Button style={{ width: '150px' }} type='primary' onClick={this.props.goLogin}>登录</Button>
                </div>}
            </div>
        )
    }
}