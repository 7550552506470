import React, { Component } from 'react'
import { Route, Link } from 'react-router-dom'
import { checkUser } from '../../utils/currentUser'
import { setCookieValue, getCookieValue } from '../../utils/cookieUtil'
import { Spin } from 'antd';

import HomeCom from './homeCom';
import VideoLists from '../VideoLists/videoLists';
import Videoplay from '../Videoplay/videoplay';
import TeacherList from '../Teacher/teacherList';
import TeacherDetail from '../Teacher/teacherDetail';
import UserCenter from '../UserCenter/usercenter';
import Copybook from '../Copybook/copybook';
import CopybookDetail from '../Copybook/copybookDetail';
import CopybookWord from '../Copybook/copybookWord';
import Circle from '../Circle/circle';
import ArticleList from '../Article/articleList';
import ArticleDetail from '../Article/articleDetail';

export default class Home extends Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            isLogin: false
        }
    }

    componentDidMount() {
        const user = JSON.parse(this.queryParam('user'))
        if (user) {
            setCookieValue('current-user', user)
            this.setState({
                isLogin: true
            }, () => {
                let localPathname = getCookieValue('localPathname')
                this.props.history.push(localPathname)
            })
        } else {
            checkUser(res => {
                if (res.code === 0) {
                    this.setState({
                        isLogin: true
                    })
                }
            })
        }
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    queryParam(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    }


    render() {
        const { isLogin } = this.state
        return (
            <Spin spinning={this.state.loading}>
                {
                    isLogin ? <div>
                        <Route path={this.props.match.url + ''} component={HomeCom} history={this.props.history} exact />
                        <Route path={this.props.match.url + '/videoLists'} component={VideoLists} history={this.props.history} />
                        <Route path={this.props.match.url + '/videoplay/:id'} component={Videoplay} history={this.props.history} />
                        <Route path={this.props.match.url + '/teacherList'} component={TeacherList} history={this.props.history} />
                        <Route path={this.props.match.url + '/teacherDetail'} component={TeacherDetail} history={this.props.history} />
                        <Route path={this.props.match.url + '/usercenter'} component={UserCenter} history={this.props.history} />
                        <Route path={this.props.match.url + '/copybook'} component={Copybook} history={this.props.history} />
                        <Route path={this.props.match.url + '/copybookDetail/:id'} component={CopybookDetail} history={this.props.history} />
                        <Route path={this.props.match.url + '/copybookWord'} component={CopybookWord} history={this.props.history} />
                        <Route path={this.props.match.url + '/circle'} component={Circle} history={this.props.history} />
                        <Route path={this.props.match.url + '/articleList'} component={ArticleList} history={this.props.history} />
                        <Route path={this.props.match.url + '/articleDetail'} component={ArticleDetail} history={this.props.history} />
                    </div> : ''
                }

            </Spin>
        )
    }
}