import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Input, message, Pagination, Breadcrumb, Empty, Modal } from 'antd'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'
import styles from './copybookDetail.module.css'

import Header from '../../components/header.js'
import Foot from '../../components/footer.js';

export default class CopybookDetail extends Component {
    constructor() {
        super()
        this.state = {
            contentHeight: 0,
            user: {},
            bookId: '',
            book: {},
            bookName: '',
            bookImgs: [],
            bookWriter: {},
            writerModalShow: false,
            bigImg: {},
            showBig: false
        }
    }

    componentDidMount() {
        const contentHeight = window.innerHeight
        this.setState({
            contentHeight,
        })
        checkUser(res => {
            if (res.code === 0) {
                let bookId = this.props.match.params.id
                this.setState({
                    user: res.user,
                    bookId: bookId
                }, () => this.getCopybookPages(bookId))
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                let bookId = this.props.match.params.id
                this.setState({
                    user: res.user,
                    bookId: bookId,
                    book: {},
                    bookName: '',
                    bookImgs: [],
                    bookWriter: {},
                }, () => this.getCopybookPages(bookId))
            }
        })
    }

    // 根据 id 获取字帖的所有页子
    getCopybookPages = (id) => {
        const { user } = this.state
        if (user.type === '-1') {
            this.$Hearder.goLogin()
            return
        }
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            copybook: id,
            id: ''
        }
        axiosPost(37, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    book: res.data,
                    bookName: res.data.name,
                    bookWriter: res.data.writer,
                    bookImgs: res.data.list
                })
            }
        })
    }

    // 查看作者详情
    writerDetail = () => {
        const { bookWriter } = this.state
        if (bookWriter.id) {
            this.setState({
                writerModalShow: true
            })
        }
    }

    // 作者详情隐藏
    writerCancel = () => {
        this.setState({
            writerModalShow: false
        })
    }

    // 显示大图
    showBigImg = (item) => {
        this.setState({
            bigImg: item,
            showBig: true
        })
    }

    // 查看单字
    goSingleWord = (e, item) => {
        e.stopPropagation()
        const { bookId, bookName } = this.state
        sessionStorage.setItem('act-copybook', bookId)
        sessionStorage.setItem('act-copybook-title', bookName)
        sessionStorage.setItem('act-copybook-image', item.id)
        this.props.history.push('/home/copybookWord')
    }

    // 结构函数
    // 标签
    getTagDiv = () => {
        const { book, bookWriter } = this.state
        let tagString = book.tags ? book.tags : ''
        let tags = tagString.split(',')
        return <p>
            <span className={styles.writername} onClick={() => this.writerDetail()}>{bookWriter.name ? bookWriter.name : ''}</span>
            {tags.map(item => <span key={item} className={styles.bookTag}>{item}</span>)}
        </p>
    }

    render() {
        const { bookName, bookImgs, writerModalShow, bookWriter, showBig, bigImg, contentHeight } = this.state
        return (
            <div style={{ height: contentHeight, overflow: 'auto' }}>
                <Header history={this.props.history} reData={this.reData} onRef={(ref) => { this.$Hearder = ref }} />
                <div className={styles.container}>
                    <div className={styles.breadcrumbBox}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Item href='/home'>首页</Breadcrumb.Item>
                            <Breadcrumb.Item href='/home/copybook'>字帖</Breadcrumb.Item>
                            <Breadcrumb.Item>{bookName}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className={styles.titleBox}>
                        <h2>{bookName}</h2>
                        {this.getTagDiv()}
                    </div>
                    <div className={styles.bookImgBox}>
                        {
                            bookImgs.length > 0 ? <Row gutter={[10, 10]}>
                                {bookImgs.map((item, index) => <Col key={item.id}>
                                    <div className={styles.imgBoxLi} onClick={() => { this.showBigImg(item) }}>
                                        <div className={styles.imgIndexBox}>{index + 1}</div>
                                        <img src={item.url} alt='' style={{ height: '100%' }} />
                                        <div className={styles.goSingleWord} onClick={(e) => this.goSingleWord(e, item)}>
                                            单字解析
                                        </div>
                                    </div>
                                </Col>)}
                            </Row> : <Empty />
                        }
                    </div>
                </div>
                <Foot />
                <Modal
                    title='作者简介'
                    visible={writerModalShow}
                    onCancel={this.writerCancel}
                    footer={null}
                    destroyOnClose
                >
                    <div className={styles.writerDetailBox}>
                        <h2>
                            <img src={bookWriter.image} alt='' />
                            <span>{bookWriter.name}</span>
                        </h2>
                        <p>
                            {bookWriter.desc}
                        </p>
                    </div>
                </Modal>
                <div className={styles.bigImgBox} style={{ display: showBig ? 'block' : 'none', height: contentHeight }} onClick={() => { this.setState({ showBig: false }) }}>
                    <img src={bigImg.url ? bigImg.url : ''} alt='' />
                </div>
            </div>
        )
    }
}