import React, { Component } from 'react'
import { Carousel, Row, Col, Timeline } from 'antd'
import { Link } from 'react-router-dom'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'
import { getVideos } from "../../utils/getVideoUtil";
import styles from './circle.module.css'

import Header from '../../components/header.js'
import Foot from '../../components/footer.js';

import Dynamic from './dynamic'
import Community from './community'
import Mine from './mine'

const pages = [
    {
        key: 'dynamic',
        title: '动态'
    }, {
        key: 'community',
        title: '社区',
    }, {
        key: 'mine',
        title: '个人中心'
    }

]

export default class Circle extends Component {
    constructor() {
        super()
        this.state = {
            contentHeight: 0,
            dynamicIndex: 0,
            dynamicSize: 15,
            dynamics: [],
            pageFlag: 'dynamic'
        }
    }

    componentDidMount() {
        const contentHeight = window.innerHeight
        this.setState({
            contentHeight,
        })
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
            }
        })
        let scrolly = this.scrollY
        let goTopButton = this.goTopButton
        document.getElementById('circleBody').addEventListener('scroll', function () {
            if (this.scrollHeight - this.scrollTop < 1100) {
                scrolly()
            }
            if (this.scrollTop > 200) {
                goTopButton('true')
            } else {
                goTopButton('false')
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    goTop = () => {
        let top = document.getElementById('circleBody').scrollTop
        let backStep = top / 50
        var scrollTopTimer = setInterval(function () {
            if (top > 0) {
                document.getElementById('circleBody').scrollTop = top - backStep
                top = top - backStep
            } else {
                clearInterval(scrollTopTimer);
            }
        }, 10)
    }

    scrollY = () => {
        this.$Dynamic.scrollY()
    }
    goTopButton = (flag) => {
        this.$Dynamic.goTopButton(flag)
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        let oldPage = this.state.pageFlag
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user,
                    pageFlag: ''
                }, () => this.setState({
                    pageFlag: oldPage
                }))
            }
        })
    }

    // 点击登录
    goLogin = () => {
        this.$Hearder.goLogin()
    }

    // 页子改变
    pageChange = (key) => {
        this.setState({
            pageFlag: key
        })
    }

    render() {
        const { pageFlag, contentHeight } = this.state
        return (
            <div style={{ height: contentHeight, overflow: 'auto' }} id='circleBody'>
                <Header history={this.props.history} reData={this.reData} onRef={(ref) => { this.$Hearder = ref }} />
                <div className={styles.container}>
                    <div className={styles.pageFlagBox}>
                        {pages.map(item =>
                            <span key={item.key} onClick={() => this.pageChange(item.key)} style={{ borderBottom: item.key === pageFlag ? '2px solid #f1281e' : 'none' }}>{item.title}</span>
                        )}
                    </div>
                    <div>
                        {pageFlag === 'dynamic' ? <Dynamic goTop={this.goTop} goLogin={this.goLogin} history={this.props.history} onRef={(ref) => { this.$Dynamic = ref }} /> :
                            pageFlag === 'community' ? <Community goLogin={this.goLogin} history={this.props.history} /> :
                                pageFlag === 'mine' ? <Mine goLogin={this.goLogin} history={this.props.history} /> : ''}
                    </div>
                </div>

                <Foot />
            </div>
        )
    }
}
