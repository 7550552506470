import React, { Component } from 'react';

import { setCookieValue } from '../utils/cookieUtil'
import { encryptByDES } from '../utils/securityUtil'
import { axiosPost, version } from '../utils/requestUtil'
import styles from './login.module.css';

import weixinIcon from '../assets/images/ic_login_weixin.png'
import deleteIcon from '../assets/images/ic_login_delete.png'
import logoIcon from '../assets/images/LOGO.png'
import { Button, message } from 'antd';

const weixinUrl = 'https://open.weixin.qq.com/connect/qrconnect?appid=wx496b389d9dee97d8&redirect_uri=http%3a%2f%2fm.jhxhzn.com%2fwxArtCallback.html&response_type=code&scope=snsapi_login&state=888#wechat_redirect'

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contentHeight: 0,
            id: '',
            password: ''
        }
    }
    componentDidMount() {
        let contentHeight = window.innerHeight
        this.setState({
            contentHeight,
        })
        window.addEventListener('resize', this.resizeBind)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeBind)
    }

    resizeBind = () => {
        let contentHeight = document.documentElement.clientHeight
        this.setState({
            contentHeight,
        })
    }

    closeClick = () => {
        this.setState({
            id: '',
            password: ''
        })
        this.props.closeClick()
    }
    weixinLoginClick = () => {
        setCookieValue('localPathname', this.props.history.location.pathname)
    }

    idChange = (e) => {
        this.setState({
            id: e.target.value
        })
    }
    passwordChange = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    checkenter = (e) => {
        if (e.charCode === 13) {
            this.login()
        }
    }

    login = () => {
        const { id, password } = this.state
        if (id.trim() === '') {
            return
        }
        if (password.trim() === '') {
            return
        }
        const params = {
            id: id,
            password: encryptByDES(password),
            brand: 'PC',
            model: 'PC',
            version: version,
            ip: ''
        }
        axiosPost(1, '', params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    id: '',
                    password: ''
                })
                setCookieValue('current-user', res.data.users[0])
                this.props.closeClick('login')
            } else {
                message.error(res.message)
            }
        })
    }

    render() {
        const { contentHeight, id, password } = this.state
        return (
            <div className={styles.loginBody} style={{ height: contentHeight, display: this.props.isLogin === '0' ? 'none' : 'flex' }}>
                {/* <div style={{ display: 'none' }}>
                    <input type="text" name="ok" value="弹出小窗口" onclick="addFiles()"></input>
                </div> */}
                <div className={styles.loginBox}>
                    <div className={styles.logoHeader}>
                        <img src={logoIcon} alt='' />
                        <img src={deleteIcon} alt='' onClick={this.closeClick} />
                    </div>
                    <div className={styles.loginIptBox}>
                        <div className={styles.input1}>
                            <input placeholder='请输入手机号' value={id} onChange={this.idChange} />
                        </div>
                        <div className={styles.input2}>
                            <input placeholder='请输入密码' value={password} type='password' onChange={this.passwordChange} onKeyPress={this.checkenter} />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Button type='primary' disabled={id.trim() === '' || password.trim() === '' ? true : false} size='large' style={{ width: '100%' }} onClick={this.login}>登录</Button>
                        </div>
                    </div>
                    <div className={styles.otherLoginBox}>
                        <h2>其他登录方式</h2>
                        <a href={weixinUrl}>
                            <img src={weixinIcon} alt='' title='微信授权登录' onClick={this.weixinLoginClick} />
                        </a>

                    </div>
                </div>

            </div>
        )
    }
}