import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

import Home from './pages/Home/home'

import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('en')

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Router>
        <div className="App">

          {/* 首页跳转 */}
          <Route path='/' exact render={() => <Redirect to='/home' />} />

          <Route path='/home' component={Home} />

        </div>
      </Router>
    </ConfigProvider>

  );
}

export default App;
