import React, { Component } from "react";
import { Row, Col, Input, Pagination, Popconfirm, Button } from 'antd'
import { axiosPost, enterprise } from '../../../utils/requestUtil'
import { getCookieValue, setCookieValue, removeCookieValue } from '../../../utils/cookieUtil'
import { checkUser } from '../../../utils/currentUser'

import styles from './userFocus.module.css'
import noRecord from '../../../assets/images/pic_no_record.png';
import pleaseLogin from '../../../assets/images/user_center_pleaseLogin.png';
import noAvatar from '../../../assets/images/header_no_avatar_circle.png';

const { Search } = Input

export default class UserFocus extends Component {
    constructor() {
        super()
        this.state = {
            user: {},
            keyword: '',
            index: 0,
            size: 24,
            userFocus: [],
            userFocusTotal: 0
        }
    }

    componentDidMount() {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                this.getFocus()
            }
        })
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    getFocus = () => {
        const { keyword, index, size } = this.state
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            keyword: keyword,
            index: index,
            size: size
        }
        axiosPost(12, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.setState({
                    userFocus: res.data.list,
                    userFocusTotal: res.data.total
                })
            } else {
                this.setState({
                    userHitories: [],
                    userHitoriesTotal: 0
                })
            }
        })
    }

    pageChange = (e) => {
        this.setState({
            index: e - 1
        }, () => this.getFocus())
    }

    searchFocus = (e) => {
        this.setState({
            keyword: e,
            index: 0
        }, () => this.getFocus())
    }

    cancelFocus = (item) => {
        const params = {
            enterprise: enterprise,
            teacher: item.id
        }
        let token = getCookieValue('current-user').token
        axiosPost(11, token, params, 'ArtVip', (res) => {
            if (res.code === 0) {
                this.getFocus()
            }
        })
    }

    goTeacherDetail = (item) => {
        sessionStorage.setItem('teacherDetail', JSON.stringify(item))
        this.props.history.push('/home/teacherDetail')
    }


    // 结构生成函数
    eachFocus = (item) => {
        return <div className={styles.eachFocus}>
            <img src={item.image} alt='' onClick={() => this.goTeacherDetail(item)}  onError={(e) => { e.target.onerror = null; e.target.src = noAvatar }} />
            <div className={styles.focusInfo}>
                <h2 onClick={() => this.goTeacherDetail(item)}>{item.name}</h2>
                <Popconfirm onConfirm={() => this.cancelFocus(item)} title="确定取消关注吗？" okText="确认" cancelText="取消">
                    <span>已关注</span>
                </Popconfirm>
            </div>
        </div>
    }

    render() {
        const { user, userFocusTotal, userFocus, index, size } = this.state
        return (
            <div>
                {user.type && user.type !== '-1' ? <div>
                    <div style={{ marginBottom: '20px' }}>
                        <Row wrap={false}>
                            <Col flex='none'>
                                <span className={styles.historyTitle}>我的关注</span>
                            </Col>
                            <Col flex='auto'>
                                <Search style={{ width: '50%' }} placeholder='输入关键字搜索' onSearch={this.searchFocus} enterButton />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        {userFocus.length > 0 ? <Row gutter={[30, 30]}>
                            {userFocus.map(item => <Col sm={12} md={6} lg={4} xl={4} xxl={4} key={item.id}>
                                {this.eachFocus(item)}
                            </Col>)}
                        </Row> : <div className={styles.noDataBox}>
                                {/* 暂无历史，赶快去学习视频吧 */}
                                <img src={noRecord} alt='' />
                            </div>}
                    </div>
                    <div className={styles.paginationBox}>
                        <Pagination
                            total={userFocusTotal}
                            showTotal={total => `共 ${total} 条数据`}
                            current={index + 1}
                            pageSize={size}
                            onChange={this.pageChange}
                            showSizeChanger={false}
                            size='default'
                            hideOnSinglePage
                        />
                    </div>
                </div> : <div style={{ textAlign: 'center' }}>
                        <img src={pleaseLogin} alt='' style={{ height: '150px' }} />
                        <p style={{ color: '#ccc' }}>请登录后查看关注</p>
                        <Button style={{ width: '150px' }} type='primary' onClick={this.props.goLogin}>登录</Button>
                    </div>}
            </div>
        )
    }
}