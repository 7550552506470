import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Input, message, Pagination, Breadcrumb, Empty, Modal, Checkbox } from 'antd'
import { enterprise, axiosPost } from '../../utils/requestUtil'
import { checkUser } from '../../utils/currentUser'
import { getCookieValue } from '../../utils/cookieUtil'
import styles from './copybookWord.module.css'

import Header from '../../components/header.js'
import Foot from '../../components/footer.js';

const { Search } = Input

export default class CopybookWord extends Component {
    constructor() {
        super()
        this.bodyDivRef = React.createRef()
        this.state = {
            contentHeight: 0,
            actCopybook: '',
            actImage: '',
            actBookname: '',
            searchKeyword: '',
            wordIndex: 0,
            wordSize: 80,
            singleWord: [],
            wordTotal: 0,
            bigImg: '',
            showBig: false
        }
    }

    componentDidMount() {
        const contentHeight = window.innerHeight
        this.setState({
            contentHeight,
        })
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                let actCopybook = sessionStorage.getItem('act-copybook')
                let actBookname = sessionStorage.getItem('act-copybook-title')
                let actImage = sessionStorage.getItem('act-copybook-image')
                this.setState({
                    actCopybook,
                    actImage,
                    actBookname,
                    wordIndex: 0
                }, () => this.getSingleWord())
            }
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    // 重新渲染页面 或 用户登录退出时需要重新获取的数据
    reData = () => {
        checkUser(res => {
            if (res.code === 0) {
                this.setState({
                    user: res.user
                })
                let actCopybook = sessionStorage.getItem('act-copybook')
                let actBookname = sessionStorage.getItem('act-copybook-title')
                let actImage = sessionStorage.getItem('act-copybook-image')
                this.setState({
                    actCopybook,
                    actImage,
                    actBookname,
                    wordIndex: 0
                }, () => this.getSingleWord())
            }
        })
    }

    // 获取单字
    getSingleWord = () => {
        const { actCopybook, actImage, searchKeyword, wordIndex, wordSize } = this.state
        let token = getCookieValue('current-user').token
        const params = {
            enterprise: enterprise,
            copybook: actCopybook,
            image: actImage,
            word: searchKeyword,
            index: wordIndex,
            size: wordSize
        }
        axiosPost(38, token, params, 'ArtVip', (res) => {
            this.bodyDivRef.current.scrollTop = 0
            if (res.code === 0) {
                this.setState({
                    singleWord: res.data.list,
                    wordTotal: res.data.total
                })
            } else {
                this.setState({
                    singleWord: [],
                    wordTotal: 0
                })
            }
        })
    }

    // 关键字搜索
    searchWord = (e) => [
        this.setState({
            searchKeyword: e,
            wordIndex: 0
        }, () => this.getSingleWord())
    ]

    // 是否查看全部单字
    checkAll = (e) => {
        if (e.target.checked) {
            this.setState({
                actImage: '',
                wordIndex: 0
            }, () => this.getSingleWord())
        } else {
            let actImage = sessionStorage.getItem('act-copybook-image')
            this.setState({
                actImage,
                wordIndex: 0
            }, () => this.getSingleWord())
        }
    }

    // 翻页
    pageChange = (e) => {
        this.setState({
            wordIndex: e - 1
        }, () => this.getSingleWord())
    }

    // 显示大图
    showBigImg = (item) => {
        this.setState({
            bigImg: item.image,
            showBig: true
        })
    }

    // 结构函数
    // 单字盒子
    getSingleWordBox = (item) => {
        return <div className={styles.singleWordBox}>
            <div className={styles.wordImgBox} onClick={() => this.showBigImg(item)}>
                <img src={item.image} alt='' />
            </div>
            <p>{item.word}</p>
        </div>
    }

    render() {
        const { contentHeight, actBookname, actCopybook, actImage, singleWord, showBig, bigImg, wordTotal, wordIndex, wordSize } = this.state
        return (
            <div style={{ height: contentHeight, overflow: 'auto' }} ref={this.bodyDivRef}>
                <Header history={this.props.history} reData={this.reData} />
                <div className={styles.container}>
                    <div className={styles.breadcrumbBox}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>当前位置</Breadcrumb.Item>
                            <Breadcrumb.Item href='/home'>首页</Breadcrumb.Item>
                            <Breadcrumb.Item href='/home/copybook'>字帖</Breadcrumb.Item>
                            <Breadcrumb.Item href={`/home/copybookDetail/${actCopybook}`}>{actBookname}</Breadcrumb.Item>
                            <Breadcrumb.Item>单字解析</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div style={{ padding: '15px 0' }}>
                        <Row gutter={[20, 0]}>
                            <Col span={6}>
                                <Search placeholder='请输入关键字查询' style={{ width: '100%' }} onSearch={this.searchWord} enterButton />
                            </Col>
                            <Col style={{ paddingTop: '5px' }}>
                                <Checkbox onChange={this.checkAll} size='large' checked={actImage ? false : true}>查看该文集全部单字解析</Checkbox>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ margin: '15px 0' }}>
                        {
                            singleWord.length > 0 ? <Row gutter={[20, 20]}>
                                {singleWord.map(item => <Col key={item.id} span={3}>
                                    {this.getSingleWordBox(item)}
                                </Col>)}
                            </Row> : <Empty />
                        }
                    </div>
                    <div className={styles.paginationBox}>
                        <Pagination
                            total={wordTotal}
                            showTotal={total => `共 ${total} 条数据`}
                            current={wordIndex + 1}
                            pageSize={wordSize}
                            onChange={this.pageChange}
                            showSizeChanger={false}
                            size='default'
                            hideOnSinglePage
                        />
                    </div>
                </div>
                <Foot />
                <div className={styles.bigImgBox} style={{ display: showBig ? 'block' : 'none', height: contentHeight }} onClick={() => { this.setState({ showBig: false }) }}>
                    <img src={bigImg} alt='' />
                </div>
            </div>
        )
    }
}