import axios from 'axios';
import { setDefault } from './currentUser'
import { getCookieValue } from './cookieUtil';
import { sign, encryptByDES, decryptByDES } from './securityUtil.js';

export const version = '1.0';
export const enterprise = '110106001'
const base = 'http://47.108.63.75:41712/api';
const config = { showLoading: true }

export async function axiosPost(action, token, params, api, callback) {
    let longTime = new Date().getTime().toString().substring(0, 10);
    let body = {
        action: action,
        parameters: params
    };
    let data = { req: encryptByDES(JSON.stringify(body)) }
    axios.create({
        headers: {
            'content-type': 'application/json'
        }
    });
    axios.defaults.headers.time = longTime;
    axios.defaults.headers.sign = sign(body, longTime);
    axios.defaults.headers.version = version;
    axios.defaults.headers.token = token;
    return axios.post(`${base}/${api}`, data, config).then(res => {
        let resdata = JSON.parse(decryptByDES(res.data.rep))
        if (resdata.code === 6) {
            let defauleId = getCookieValue('default-id')
            setDefault(defauleId, res => {
                if (res.code === 0) {
                    let newToken = getCookieValue('current-user').token
                    axiosPost(action, newToken, params, api, callback)
                }
            })
        } else {
            callback(resdata);
        }
    });
}

export async function axiosPut(action, token, params, files, api, callback) {
    let longTime = new Date().getTime().toString().substring(0, 10);
    let body = {
        action: action,
        parameters: params
    };
    let data = { req: encryptByDES(JSON.stringify(body)) }
    axios.create({
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    axios.defaults.headers.time = longTime;
    axios.defaults.headers.sign = sign(body, longTime);
    axios.defaults.headers.version = version;
    axios.defaults.headers.token = token;
    let form = new FormData()
    form.append('json', JSON.stringify(data))
    if (files.length > 0) {
        files.forEach(item=>form.append('file',item))
    }
    return axios.put(`${base}/${api}`, form, config).then(res => {
        let resdata = JSON.parse(decryptByDES(res.data.rep))
        if (resdata.code === 6) {
            let defauleId = getCookieValue('default-id')
            setDefault(defauleId, res => {
                if (res.code === 0) {
                    let newToken = getCookieValue('current-user').token
                    axiosPost(action, newToken, params, files, api, callback)
                }
            })
        } else {
            callback(resdata);
        }
    });
}


