import React, { Component } from 'react';
import { message } from 'antd'
import styles from './imagesList.module.css'

import rotate from '../assets/images/ic_finding_rotate.png'
import deleteImg from '../assets/images/ic_circle_dynamic_imgDelete.png'
import beforeImg from '../assets/images/before.png'
import nextImg from '../assets/images/next.png'

let deg = 0
export default class ImagesList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contentHeight: 0,
            images: this.props.sources,
            actImg: this.props.actSource
        }
    }

    componentDidMount() {
        let contentHeight = window.innerHeight
        this.setState({
            contentHeight,
        })
    }

    componentWillUnmount() {
        deg = 0
    }

    leftRotate = (e) => {
        e.stopPropagation()
        deg += 90
        var img = document.getElementById('actImg')
        img.style.transform = "rotate(" + deg + "deg)";
    }

    actChange = (item) => {
        deg = 0
        var img = document.getElementById('actImg')
        img.style.transform = "rotate(" + deg + "deg)";
        this.setState({
            actImg: item
        })
    }

    hideCom = () => {
        this.props.hide()
    }

    beforeImg = () => {
        const { actImg, images } = this.state
        deg = 0
        var img = document.getElementById('actImg')
        img.style.transform = "rotate(" + deg + "deg)";
        let actIndex = images.indexOf(actImg)
        if (actIndex > 0) {
            this.setState({
                actImg: images[actIndex - 1]
            })
        } else {
            message.destroy()
            message.error('当前为第一张图片，不能往前查看')
        }
    }

    nextImg = () => {
        const { actImg, images } = this.state
        deg = 0
        var img = document.getElementById('actImg')
        img.style.transform = "rotate(" + deg + "deg)";
        let actIndex = images.indexOf(actImg)
        if (actIndex + 1 < images.length) {
            this.setState({
                actImg: images[actIndex + 1]
            })
        } else {
            message.destroy()
            message.error('当前为最后一张图片，不能继续查看')
        }
    }

    render() {
        const { contentHeight, images, actImg } = this.state
        return (
            <div className={styles.bigImgBody} style={{ height: contentHeight }} onClick={this.props.hide}>
                <div className={styles.bigImgBox}>
                    <img className={styles.deleteImg} src={deleteImg} alt='' onClick={this.hideCom} />
                    <div className={styles.actImgBox} style={{ height: contentHeight - 80 }} onClick={(e) => e.stopPropagation()}>
                        <img src={actImg} alt='' id='actImg' />
                        <div className={styles.rotateBox}><img onClick={(e) => this.leftRotate(e)} src={rotate} alt='' /></div>
                        <div className={styles.beforeImgBox} onClick={this.beforeImg}><img src={beforeImg} alt='' /></div>
                        <div className={styles.nextImgBox} onClick={this.nextImg}><img src={nextImg} alt='' /></div>
                    </div>
                    <div className={styles.imgListBox} style={{ height: '80px' }} onClick={(e) => e.stopPropagation()}>
                        <div>
                            {images.map((item, index) => <img key={index} src={item} alt='' onClick={() => this.actChange(item)} style={{ border: actImg === item ? '2px solid #73b4e0' : 'none' }} />)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}