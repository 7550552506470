import React, { Component } from 'react';
import styles from './footer.module.css';

export default class Foot extends Component {
    constructor() {
        super()
        this.state = {

        }
    }
    render() {
        return (
            <div>
                <footer className={styles.footerBox}>
                    <dl>
                        <dt>新闻中心</dt>
                        <dd>咨询客服</dd>
                        <dd>联系我们</dd>
                        <dd>案例查看</dd>
                        <dd>广告招商</dd>
                    </dl>
                    <dl>
                        <dt>新闻中心</dt>
                        <dd>咨询客服</dd>
                        <dd>联系我们</dd>
                        <dd>案例查看</dd>
                        <dd>广告招商</dd>
                    </dl>
                    <dl>
                        <dt>新闻中心</dt>
                        <dd>咨询客服</dd>
                        <dd>联系我们</dd>
                        <dd>案例查看</dd>
                        <dd>广告招商</dd>
                    </dl>
                    <dl>
                        <dt>新闻中心</dt>
                        <dd>咨询客服</dd>
                        <dd>联系我们</dd>
                        <dd>案例查看</dd>
                        <dd>广告招商</dd>
                    </dl>
                </footer>
            </div>
        )
    }
}